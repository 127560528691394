import RouterError from '../../shared/RouterError'
import UnauthenticatedHeader from '../../shared/UnauthenticatedHeader'

import { Button, Intent } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

const SSOFailureView = ({ message }) => {
  return (
    <div className="sso-failure-container">
      <UnauthenticatedHeader />
      <RouterError
        icon="ban-circle"
        title="Login Failed"
        animationName="headShake"
        animationSpeed="fast"
        description={message ?? 'Unknown Error'}
        action={
          <Link to="/">
            <Button
              icon="repeat"
              text="Try again"
              intent={Intent.PRIMARY}
              minimal
              large
            />
          </Link>
        }
      />
    </div>
  )
}

export default SSOFailureView
