import {
  USER_INVITATIONS_REQUESTED,
  USER_INVITATIONS_REQUEST_SUCCESS,
  USER_INVITATIONS_REQUEST_FAILURE,
  USER_INVITATION_REVOKE_SUCCESS
} from './actions'

const defaultState = {}

const userInvitations = (state = defaultState, action) => {
  switch (action.type) {
    case USER_INVITATIONS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case USER_INVITATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        invitations: action.invitations
      }
    case USER_INVITATIONS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case USER_INVITATION_REVOKE_SUCCESS:
      return {
        ...state,
        invitations: state.invitations.filter(
          (invitation) => invitation.user !== action.user
        )
      }
    default:
      return state
  }
}

export default userInvitations
