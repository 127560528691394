import './styles.scss'
import Filters from '../shared/Filters'
import Table from '../shared/Table'
import PageSizeSelector from './PageSizeSelector'
import PageJumper from './PageJumper'

import { Button } from '@blueprintjs/core'

const RequestsView = ({
  items,
  loading,
  error,
  refetch,
  archiveRequest,
  archiveVoucher,
  downloadVoucher,
  selectVoucher,
  deselectVoucher,
  selectMultipleVouchers,
  deselectMultipleVouchers,
  pageSize,
  pageSizeChanged,
  currentPage,
  pageNumberChanged,
  hasNextPage,
  nextPageRequested,
  previousPageRequested,
  selectedVouchers,
  init
}) => {
  return (
    <div className="requests-container">
      {init && <Filters items={items} loading={loading} />}
      <Table
        loading={loading}
        error={error}
        items={items}
        refetch={refetch}
        archiveRequest={archiveRequest}
        archiveVoucher={archiveVoucher}
        downloadVoucher={downloadVoucher}
        selectVoucher={selectVoucher}
        deselectVoucher={deselectVoucher}
        selectAllVouchers={selectMultipleVouchers}
        deselectAllVouchers={deselectMultipleVouchers}
        selectedVouchers={selectedVouchers}
      />
      <div className="requests-pagination-controls animate__animated animate__fadeInUp animate__fastest">
        <PageSizeSelector
          pageSize={pageSize}
          pageSizeChanged={pageSizeChanged}
          disabled={loading}
        />
        <PageJumper
          currentPage={currentPage}
          pageNumberChanged={pageNumberChanged}
          disabled={loading}
          hasNextPage={hasNextPage}
        />
        <div className="page-control">
          <Button
            className="previous-page"
            disabled={loading || currentPage === 1}
            onClick={previousPageRequested}
            icon="chevron-left"
            minimal
            small
          />
          <Button
            className="next-page"
            disabled={loading || !hasNextPage}
            onClick={nextPageRequested}
            icon="chevron-right"
            minimal
            small
          />
        </div>
      </div>
    </div>
  )
}

export default RequestsView
