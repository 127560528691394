import {
  REQUESTS_REQUESTED,
  REQUESTS_REQUEST_FAILURE,
  REQUESTS_REQUEST_SUCCESS,
  RESET_REQUESTS_DATA,
  PREVIOUS_PAGE_REQUESTED,
  NEXT_PAGE_REQUESTED,
  PAGE_SIZE_CHANGED,
  PAGE_NUMBER_CHANGED
} from './actions'
import { REQUEST_ARCHIVE_SUCCESS } from '../Request/actions'
import {
  VOUCHER_SELECTED,
  VOUCHER_DESELECTED,
  VOUCHER_MULTI_SELECTED,
  VOUCHER_MULTI_DESELECTED,
  RESET_VOUCHER_SELECTION,
  VOUCHER_ARCHIVE_SUCCESS,
  VOUCHER_BULK_ARCHIVE_SUCCESS
} from '../Voucher/actions'
import {
  bulkArchiveVouchers,
  archiveVoucher,
  deselectMultipleVouchers,
  deselectVoucher,
  selectMultipleVouchers,
  selectVoucher
} from '../Voucher/utils'
import { PAGE_SIZES } from './constants'

const defaultState = {
  selectedVouchers: {},
  pageSize: PAGE_SIZES[1],
  currentPage: 1
}

const requests = (state = defaultState, action) => {
  switch (action.type) {
    case REQUESTS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case REQUESTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        requests: action.requests,
        hasNextPage: action.hasNextPage
      }
    case REQUESTS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        requests: null,
        hasNextPage: undefined
      }
    case VOUCHER_ARCHIVE_SUCCESS:
      return {
        ...state,
        requests: archiveVoucher(state.requests, action.id)
      }
    case REQUEST_ARCHIVE_SUCCESS:
    case VOUCHER_BULK_ARCHIVE_SUCCESS:
      return {
        ...state,
        requests: bulkArchiveVouchers(state.requests, action.vouchers)
      }
    case VOUCHER_SELECTED:
      return {
        ...state,
        selectedVouchers: selectVoucher(state.selectedVouchers, action.id)
      }
    case VOUCHER_DESELECTED:
      return {
        ...state,
        selectedVouchers: deselectVoucher(state.selectedVouchers, action.id)
      }
    case VOUCHER_MULTI_SELECTED:
      return {
        ...state,
        selectedVouchers: selectMultipleVouchers(
          state.selectedVouchers,
          action.vouchers
        )
      }
    case VOUCHER_MULTI_DESELECTED:
      return {
        ...state,
        selectedVouchers: deselectMultipleVouchers(
          state.selectedVouchers,
          action.vouchers
        )
      }
    case PREVIOUS_PAGE_REQUESTED:
      if (state.currentPage > 0)
        return {
          ...state,
          currentPage: state.currentPage - 1
        }
      else return state
    case NEXT_PAGE_REQUESTED:
      if (state.hasNextPage)
        return {
          ...state,
          currentPage: state.currentPage + 1
        }
      else return state
    case PAGE_SIZE_CHANGED:
      if (action.pageSize > 0)
        return {
          ...state,
          pageSize: action.pageSize
        }
      else return state
    case PAGE_NUMBER_CHANGED:
      if (action.pageNumber > 0)
        return {
          ...state,
          currentPage: action.pageNumber
        }
      else return state
    case RESET_VOUCHER_SELECTION:
      return {
        ...state,
        selectedVouchers: {}
      }
    case RESET_REQUESTS_DATA:
      return defaultState
    default:
      return state
  }
}

export default requests
