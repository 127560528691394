import Toast from '../shared/Toast'

export const USER_AGREEMENT_ACCEPT_REQUESTED = 'USER_AGREEMENT_ACCEPT_REQUESTED'
export const USER_AGREEMENT_ACCEPT_SUCCESS = 'USER_AGREEMENT_ACCEPT_SUCCESS'
export const USER_AGREEMENT_ACCEPT_FAILED = 'USER_AGREEMENT_ACCEPT_FAILED'

const acceptRequested = () => {
  return {
    type: USER_AGREEMENT_ACCEPT_REQUESTED
  }
}

const acceptSuccess = () => {
  return {
    type: USER_AGREEMENT_ACCEPT_SUCCESS
  }
}

const acceptFailed = (error) => {
  return {
    type: USER_AGREEMENT_ACCEPT_FAILED,
    error
  }
}

export const acceptUserAgreement = () => async (dispatch) => {
  dispatch(acceptRequested())
  try {
    const response = await fetch('/api/users/uca/accept', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()

    if (json.ok) {
      dispatch(acceptSuccess())
      localStorage.setItem('redirectURL', '/home')
      window.location.reload()
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(acceptFailed(e))
    Toast.show({
      message: `Failed to record acceptance - ${e}`,
      intent: 'danger',
      action: {
        text: 'Retry',
        icon: 'repeat',
        onClick: () => dispatch(acceptUserAgreement())
      }
    })
  }
}
