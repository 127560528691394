import './styles.scss'

import { Button } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

const UserAgreementView = ({ accept, logout }) => {
  return (
    <div className="user-agreement-container">
      <div className="user-agreement-header">
        <div className="masa-logo" />
        <div className="title">General Terms</div>
      </div>
      <div className="user-agreement-body">
        <div className="agreement-container">
          <div className="agreement-message">
            <p>
              By clicking 'Accept' or using the Cisco Technology, you agree that
              such use is governed by the&nbsp;
              <a
                className="agreement-link"
                href="https://www.cisco.com/c/dam/en_us/about/doing_business/legal/Cisco_General_Terms.pdf"
              >
                General Terms.
              </a>
              &nbsp;You also acknowledge and agree that you have read the&nbsp;
              <a
                href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html"
                className="agreement-link"
              >
                Cisco Privacy Statement
              </a>
              .
            </p>
            <p>
              If you do not have authority to bind your company and its
              affiliates, or if you do not agree with the General Terms, do not
              click 'Accept' and do not use the Cisco Technology. If you are a
              Cisco channel partner accepting on behalf of an end customer
              ("customer"), you must inform the customer that the General Terms
              apply to customer's use of the Cisco Technology and provide the
              customer with access to all relevant terms.
            </p>
          </div>
        </div>
      </div>
      <div className="user-agreement-footer">
        <Popover2
          popoverClassName="user-agreement-cancel-popover"
          content={
            <div className="user-agreement-cancel-info">
              <div className="message">
                You must accept the General Terms in order to use this service
              </div>
              <Button onClick={logout}>Logout</Button>
            </div>
          }
        >
          <Button large>Cancel</Button>
        </Popover2>
        <Button
          intent="primary"
          className="accept-button"
          onClick={accept}
          large
        >
          Accept
        </Button>
      </div>
    </div>
  )
}

export default UserAgreementView
