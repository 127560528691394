import './styles.scss'
import { pluralize } from '../utils'

import { useState } from 'react'
import { MenuItem, Button } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'

const Selector = ({
  id,
  name,
  items,
  onItemSelect,
  selectedItems,
  multi,
  disabled,
  className,
  showAllForEmptySelection,
  pluralSuffix = 's',
  prefix = 'Select',
  icon,
  highlightWhenSelected
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const renderButtonText = () => {
    if (selectedItems.length) {
      if (selectedItems.length > 2)
        return (
          selectedItems.slice(0, 2).join(', ') +
          `, and ${pluralize({
            word: 'other',
            count: selectedItems.length - 2
          })}`
        )
      return selectedItems.join(', ')
    }

    if (showAllForEmptySelection)
      return `All ${pluralize({
        word: name,
        count: 0,
        pluralAdditive: pluralSuffix,
        useNoForZero: false,
        ignoreQuantity: true
      })}`

    return `${prefix} ${pluralize({
      word: name,
      count: multi ? 0 : 1,
      pluralAdditive: pluralSuffix,
      useNoForZero: false,
      ignoreQuantity: true
    })}`
  }

  const renderItem = (item, { handleClick }) => {
    return (
      <MenuItem
        key={item}
        text={item}
        icon={selectedItems.includes(item) ? 'tick' : 'blank'}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    )
  }

  return (
    <div className={`shared-selector ${className}`}>
      <Select2
        className="shared-select"
        items={items}
        itemRenderer={renderItem}
        itemPredicate={(query, item) =>
          item?.toLowerCase().includes(query?.toLowerCase())
        }
        onItemSelect={(event) => {
          onItemSelect(id, event, multi)
          if (!multi) setIsOpen(false)
        }}
        noResults={<MenuItem text="No results found" disabled />}
        popoverProps={{
          popoverClassName: 'shared-selector-popover',
          isOpen: isOpen,
          onClose: () => setIsOpen(false),
          minimal: true,
          fill: true,
          placement: 'bottom-start'
        }}
      >
        <Button
          className="shared-selector-button"
          disabled={disabled}
          icon={icon}
          text={renderButtonText()}
          rightIcon="double-caret-vertical"
          onClick={() => setIsOpen(!isOpen)}
          intent={
            highlightWhenSelected && selectedItems.length
              ? 'primary'
              : undefined
          }
        />
      </Select2>
    </div>
  )
}

export default Selector
