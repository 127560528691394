import OrgSwitcherView from './OrgSwitcherView'
import { fetchMyOrganizations, switchOrganization } from './actions'

import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const OrgSwitcher = () => {
  const { loading, organizations, currentOrganization } = useSelector(
    (state) => state.orgSwitcher
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchMyOrganizations())
  }, [dispatch])

  const _switchOrganization = useCallback(
    (organization) => dispatch(switchOrganization(organization)),
    [dispatch]
  )

  const filterOrganizations = useCallback((query, item) => {
    return item.org_name.toLowerCase().includes(query.toLowerCase())
  }, [])

  return organizations?.length > 1 ? (
    <OrgSwitcherView
      loading={loading}
      organizations={organizations.filter(
        (organization) => organization.org_id !== currentOrganization?.org_id
      )}
      currentOrganization={currentOrganization}
      filterOrganizations={filterOrganizations}
      switchOrganization={_switchOrganization}
    />
  ) : (
    <></>
  )
}

export default OrgSwitcher
