import './styles.scss'

import { Button, Intent, NonIdealState } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

const RouterError = ({
  title,
  description,
  icon,
  action,
  animationName = 'pulse',
  animationSpeed = 'faster'
}) => {
  return (
    <NonIdealState
      className={`router-error animate__animated animate__${animationName} animate__${animationSpeed}`}
      icon={icon}
      title={title}
      description={description}
      action={action}
    />
  )
}

RouterError.defaultProps = {
  icon: 'error',
  title: 'Page Not Found',
  description: 'The page you are looking for could not be found',
  action: (
    <Link to="/home">
      <Button icon="home" text="Home" intent={Intent.PRIMARY} />
    </Link>
  )
}

export default RouterError
