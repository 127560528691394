import {
  SET_NEW_CERTIFICATE_INPUT,
  RESET_NEW_CERTIFICATE,
  CERTIFICATES_REQUESTED,
  CERTIFICATES_REQUEST_FAILURE,
  CERTIFICATES_REQUEST_SUCCESS,
  CERTIFICATE_ADD_REQUESTED,
  CERTIFICATE_ADD_SUCCESS,
  CERTIFICATE_ADD_FAILURE,
  CERTIFICATE_REMOVE_REQUESTED,
  CERTIFICATE_REMOVE_SUCCESS,
  CERTIFICATE_REMOVE_FAILURE,
  CERTIFICATE_NEW_FILE_LOADED
} from './actions'

const defaultState = {
  newCertificate: {
    content: '',
    expires_at: null,
    revocation_checks: false,
    name: ''
  },
  certificates: [],
  certificateFile: null
}

const certificateManager = (state = defaultState, action) => {
  switch (action.type) {
    case CERTIFICATE_NEW_FILE_LOADED:
      return {
        ...state,
        certificateFile: action.certificateFile
      }
    case SET_NEW_CERTIFICATE_INPUT:
      let newCertificateData = { ...state.newCertificate }
      newCertificateData[action.field] = action.value

      let newCertificateFile = state.certificateFile
      if (action.field === 'content') {
        newCertificateFile = null
      }

      return {
        ...state,
        newCertificate: newCertificateData,
        certificateFile: newCertificateFile
      }
    case RESET_NEW_CERTIFICATE:
      return {
        ...state,
        newCertificate: defaultState.newCertificate,
        certificateFile: null
      }
    case CERTIFICATES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case CERTIFICATES_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        certificates: action.certificates
      }
    case CERTIFICATES_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case CERTIFICATE_ADD_REQUESTED:
      return {
        ...state,
        submitting: true
      }
    case CERTIFICATE_ADD_SUCCESS:
      return {
        ...state,
        submitting: false,
        newCertificate: defaultState.newCertificate,
        certificateFile: null,
        certificates: [action.certificate, ...state.certificates]
      }
    case CERTIFICATE_ADD_FAILURE:
      return {
        ...state,
        submitting: false
      }
    case CERTIFICATE_REMOVE_REQUESTED:
      return {
        ...state,
        submitting: true
      }
    case CERTIFICATE_REMOVE_SUCCESS:
      return {
        ...state,
        submitting: false,
        certificates: state.certificates.filter(
          (certificate) => certificate.cert_id !== action.id
        )
      }
    case CERTIFICATE_REMOVE_FAILURE:
      return {
        ...state,
        submitting: false
      }
    default:
      return state
  }
}
export default certificateManager
