import './styles.scss'
import Login from '../Login'

import { Link } from 'react-router-dom'
import { Spinner, Tag } from '@blueprintjs/core'
import classNames from 'classnames'
import Alerts from './Alerts'

const LandingView = ({
  releasesLoading,
  releasesLoadingError,
  currentRelease
}) => {
  return (
    <div className="landing-container">
      <Alerts />
      <div className="masa-logo" title="Cisco MASA" />
      <Login />
      <div className="landing-footer">
        <div className="landing-footer-links animate__animated animate__fadeIn animate__faster">
          <Link
            to="/releases"
            className={classNames('landing-footer-link landing-version', {
              hidden: !releasesLoading && releasesLoadingError
            })}
            title="Release Notes"
          >
            <Tag icon="tag" intent="warning" round interactive>
              {releasesLoading && <Spinner size={10} intent="warning" />}
              {!releasesLoading && !releasesLoadingError && (
                <span>v{currentRelease?.version}</span>
              )}
            </Tag>
          </Link>
          <Link
            to="/docs"
            className="landing-footer-link landing-docs"
            title="Docs"
          >
            <Tag icon="code" intent="primary" round interactive>
              Docs
            </Tag>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LandingView
