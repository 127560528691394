import { SET_NAV_TITLE } from './actions'

const defaultState = {
  title: [{ text: 'MASA' }],
  subTitle: 'Home'
}

const nav = (state = defaultState, action) => {
  switch (action.type) {
    case SET_NAV_TITLE:
      return {
        ...state,
        title: action.title,
        subTitle: action.subTitle
      }
    default:
      return state
  }
}
export default nav
