import Toast from '../../shared/Toast'

export const RELATED_VOUCHERS_REQUESTED = 'RELATED_VOUCHERS_REQUESTED'
export const RELATED_VOUCHERS_REQUEST_SUCCESS =
  'RELATED_VOUCHERS_REQUEST_SUCCESS'
export const RELATED_VOUCHERS_REQUEST_FAILURE =
  'RELATED_VOUCHERS_REQUEST_FAILURE'

const relatedVouchersRequested = (id) => {
  return {
    type: RELATED_VOUCHERS_REQUESTED,
    id
  }
}

const relatedVouchersRequestSuccess = (vouchers) => {
  return {
    type: RELATED_VOUCHERS_REQUEST_SUCCESS,
    vouchers
  }
}

const relatedVouchersRequestFailure = (id, error) => {
  return {
    type: RELATED_VOUCHERS_REQUEST_FAILURE,
    id,
    error
  }
}

export const fetchRelatedVouchers = (id) => async (dispatch) => {
  dispatch(relatedVouchersRequested())

  try {
    const response = await fetch(`/api/history/voucher/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(relatedVouchersRequestSuccess(json.vouchers))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(relatedVouchersRequestFailure(id, e))
    Toast.show({
      message: `Failed to fetch related vouchers ${id} - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(fetchRelatedVouchers(id))
      }
    })
  }
}
