import './styles.scss'
import Field from './Field'

import { pluralize, humanizeDateTime } from '../shared/utils'

import { CSSTransition } from 'react-transition-group'
import {
  Button,
  ButtonGroup,
  Classes,
  Icon,
  MenuItem,
  Switch,
  Tag
} from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import { Popover2 } from '@blueprintjs/popover2'
import { OS_TYPES, OS_TYPES_DISPLAY_MAP } from './constants'
import SecurityProfileSelector from './SecurityProfileSelector'
import Moment from 'moment'
import { Select2 } from '@blueprintjs/select'
import { Link } from 'react-router-dom'
import { DateValue } from '../shared/Renderers'
import moment from 'moment'

const RequestFormView = ({
  fieldChanged,
  fileInputChanged,
  certificate,
  serialNumbers,
  pkCertificate,
  certificateFile,
  serialNumbersFile,
  pkCertificateFile,
  override,
  overrideFieldToggled,
  securityProfile,
  securityProfileFieldToggled,
  expiresAt,
  voucherExpiryChanged,
  osType,
  osTypeFieldChanged,
  onSubmit,
  isProcessing,
  loading,
  invalid,
  duplicate,
  onClearAll,
  usePreUploadedCertificate,
  toggleUsePreUploadedCertificate,
  preUploadedCertificates,
  certificatesLoading,
  selectedCertificate,
  setSelectedCertificate
}) => {
  return (
    <div className="request-form-container">
      <div className="request-form-options">
        <div className="request-form-basic-options">
          <Switch
            checked={usePreUploadedCertificate}
            onChange={toggleUsePreUploadedCertificate}
            label="Use Pre-Uploaded Certificate"
            className="certificate-input-type-switch"
            alignIndicator="right"
          />
          {usePreUploadedCertificate ? (
            <div className="select-certificate-input-section">
              <div className="field-header">
                <div className="field-header-main">
                  <Icon className="field-header-main-icon" icon="label" />
                  <div className="field-header-main-title">
                    Pinned Domain Certificate
                  </div>
                  <div className="field-header-main-required" title="Required">
                    *
                  </div>
                </div>
                <Button
                  className="clear-selected-certificate animate__animated animate__zoomIn animate__fastest"
                  icon="cross"
                  minimal
                  intent="danger"
                  text={selectedCertificate && 'Clear Selection'}
                  disabled={!selectedCertificate}
                  onClick={() => setSelectedCertificate(null)}
                  small
                />
              </div>
              <Select2
                multiline
                fill={true}
                className="select-certificate-input"
                onItemSelect={(item) => setSelectedCertificate(item)}
                items={preUploadedCertificates}
                itemRenderer={(item, { handleClick }) => {
                  const isExpired = moment().isAfter(item.expires_at)
                  return (
                    <MenuItem
                      key={item.cert_id}
                      disabled={isExpired}
                      text={
                        <div className="select-item-certificate">
                          <div className="select-item-certificate-header">
                            {item.pdc_organization}
                            {item.name && (
                              <div className="select-item-certificate-name">
                                {item.name}
                              </div>
                            )}
                          </div>
                          <div className="select-item-certificate-info">
                            <div className="select-item-certificate-info-left">
                              <Icon
                                size={8}
                                icon="outdated"
                                className="expires-at-icon"
                              />
                              <DateValue date={item.expires_at} />
                            </div>
                            <div className="select-item-certificate-info-right">
                              {isExpired && (
                                <div className="expired-indicator">
                                  <Icon size={10} icon="error" />
                                  Expired
                                </div>
                              )}
                              {item.revocation_checks && (
                                <Icon
                                  size={10}
                                  icon="eye-on"
                                  className="revocation-check-indicator"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      }
                      active={selectedCertificate === item}
                      onClick={handleClick}
                    />
                  )
                }}
                popoverProps={{
                  popoverClassName: 'select-certificate-popover',
                  placement: 'bottom-start',
                  minimal: true
                }}
                itemPredicate={(query, item) =>
                  item?.name?.toLowerCase().includes(query?.toLowerCase()) ||
                  item?.cert_id?.toLowerCase().includes(query?.toLowerCase()) ||
                  item?.pdc_organization
                    ?.toLowerCase()
                    .includes(query?.toLowerCase())
                }
                noResults={
                  <MenuItem
                    className="animate__animated animate__headShake animate__fast"
                    text="No certificates found"
                    disabled
                  />
                }
              >
                <Button
                  fill
                  className="certificate-select-button animate__animated animate__fadeInUp animate__fastest"
                  rightIcon="double-caret-vertical"
                  loading={certificatesLoading}
                  disabled={
                    certificatesLoading || !preUploadedCertificates.length
                  }
                  text={
                    certificatesLoading ? (
                      'Loading'
                    ) : selectedCertificate ? (
                      <div className="selected-certificate-display">
                        <div className="selected-certificate-identifiers">
                          <div className="selected-certificate-org">
                            {selectedCertificate.pdc_organization}
                          </div>
                          {selectedCertificate.name && (
                            <div className="selected-certificate-info">
                              ({selectedCertificate.name})
                            </div>
                          )}
                        </div>
                        <div className="selected-certificate-info">
                          <Icon
                            size={10}
                            icon="outdated"
                            className="expires-at-icon"
                          />
                          <DateValue date={selectedCertificate?.expires_at} />
                          {selectedCertificate.revocation_checks && (
                            <Icon
                              className="revocation-checks-icon"
                              size={12}
                              icon="eye-on"
                            />
                          )}
                        </div>
                      </div>
                    ) : preUploadedCertificates.length ? (
                      'Select a Certificate'
                    ) : (
                      'No Certificates Uploaded'
                    )
                  }
                />
              </Select2>
              {!certificatesLoading && !preUploadedCertificates.length ? (
                <div>
                  <Tag
                    className="no-certificates-added-warning animate__animated animate__fadeInDown animate__faster"
                    intent="primary"
                    icon="info-sign"
                    round
                    minimal
                  >
                    Upload Certificates from the&nbsp;
                    <Link to="/settings" title="Settings">
                      Settings
                    </Link>
                    &nbsp;page to use them here
                  </Tag>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <Field
              id="certificate"
              name="Pinned Domain Certificate"
              icon="label"
              placeholder="Drag or Choose a file, Paste or Enter Certificate in PEM format"
              fieldChanged={fieldChanged}
              fileInputChanged={fileInputChanged}
              value={certificate}
              selectedFile={certificateFile}
              isProcessing={isProcessing}
              onClearAll={onClearAll}
              className="new-certificate-input"
              required
            />
          )}

          <Field
            id="serialNumbers"
            name="Serial Numbers"
            icon="array-numeric"
            placeholder="Drag or Choose a file, Paste or Enter Serial Numbers"
            fieldChanged={fieldChanged}
            fileInputChanged={fileInputChanged}
            value={serialNumbers}
            selectedFile={serialNumbersFile}
            isProcessing={isProcessing}
            onClearAll={onClearAll}
            required
          />
          <CSSTransition
            in={serialNumbers.length > 0}
            timeout={300}
            classNames="serial-numbers-validation"
            unmountOnExit
          >
            <div className="serial-numbers-validation-text">
              <Tag
                icon="info-sign"
                className="valid-count"
                intent="primary"
                minimal
                round
              >
                {pluralize({
                  word: 'serial number',
                  count: serialNumbers.length,
                  useNoForZero: true
                })}
                &nbsp;{serialNumbersFile ? 'added' : 'entered'}
              </Tag>
              {serialNumbersFile && (
                <>
                  {invalid?.length > 0 && (
                    <Popover2
                      interactionKind="hover"
                      popoverClassName="invalid-sno-popover"
                      className="invalid-count"
                      placement="bottom"
                      content={
                        <div className="invalid-serial-numbers-list">
                          {invalid.map((item, i) => (
                            <Tag
                              key={`invalid-sno-${i}`}
                              intent="danger"
                              minimal
                            >
                              {item}
                            </Tag>
                          ))}
                        </div>
                      }
                    >
                      <Tag
                        icon="error"
                        intent="danger"
                        interactive
                        minimal
                        round
                      >
                        {invalid.length} invalid
                      </Tag>
                    </Popover2>
                  )}
                  {duplicate?.length > 0 && (
                    <Popover2
                      interactionKind="hover"
                      popoverClassName="invalid-sno-popover"
                      className="duplicate-count"
                      placement="bottom"
                      content={
                        <div className="invalid-serial-numbers-list">
                          {duplicate.map((item, i) => (
                            <Tag
                              key={`duplicate-sno-${i}`}
                              intent="warning"
                              minimal
                            >
                              {item}
                            </Tag>
                          ))}
                        </div>
                      }
                    >
                      <Tag
                        icon="duplicate"
                        intent="warning"
                        interactive
                        minimal
                        round
                      >
                        {pluralize({
                          word: 'duplicate',
                          count: duplicate.length
                        })}
                      </Tag>
                    </Popover2>
                  )}
                </>
              )}
            </div>
          </CSSTransition>
          <Field
            id="pkCertificate"
            name="Platform Key Certificate"
            icon="endorsed"
            placeholder="Drag or Choose a file, Paste or Enter Certificate in PEM format"
            fieldChanged={fieldChanged}
            fileInputChanged={fileInputChanged}
            value={pkCertificate}
            selectedFile={pkCertificateFile}
            isProcessing={isProcessing}
            onClearAll={onClearAll}
            disabled={osType !== OS_TYPES.IOS_XR || securityProfile}
            info="Provide this certificate when you need to generate an extended OV"
          />
        </div>
        <div className="request-form-advanced-options">
          <div className="mini-field voucher-expiry-selector">
            <div
              className="mini-field-header"
              title="Enable this toggle to include a security profile in the voucher"
            >
              <Icon icon="calendar" />
              Expiry
            </div>
            <Popover2
              content={
                <DatePicker
                  timePrecision="minute"
                  timePickerProps={{
                    showArrowButtons: true,
                    useAmPm: true
                  }}
                  onChange={(date) =>
                    Moment(date).isAfter(Moment())
                      ? voucherExpiryChanged(date)
                      : voucherExpiryChanged(Moment().add(1, 'hour').toDate())
                  }
                  minDate={Moment().toDate()}
                  maxDate={Moment().add(1, 'years').toDate()}
                  value={expiresAt}
                  shortcuts={[
                    {
                      date: Moment().add(180, 'days').toDate(),
                      label: '6 months',
                      includeTime: true
                    },
                    {
                      date: Moment().add(90, 'days').toDate(),
                      label: '3 months',
                      includeTime: true
                    },
                    {
                      date: Moment().add(30, 'days').toDate(),
                      label: '1 month',
                      includeTime: true
                    },
                    {
                      date: Moment().add(7, 'days').toDate(),
                      label: '1 week',
                      includeTime: true
                    },
                    {
                      date: Moment().add(1, 'day').toDate(),
                      label: '1 day',
                      includeTime: true
                    },
                    {
                      date: Moment().add(12, 'hour').toDate(),
                      label: '12 hours',
                      includeTime: true
                    },
                    {
                      date: Moment().add(1, 'hour').toDate(),
                      label: '1 hour',
                      includeTime: true
                    },
                    {
                      date: Moment().add(30, 'minutes').toDate(),
                      label: '30 minutes',
                      includeTime: true
                    }
                  ]}
                  highlightCurrentDay
                  footerElement={
                    <Button
                      className={Classes.POPOVER_DISMISS}
                      onClick={() => voucherExpiryChanged(null)}
                      icon="reset"
                      text="Reset Selection"
                      minimal
                      small
                    />
                  }
                />
              }
            >
              <Button
                className="voucher-expiry-selection-button"
                intent="primary"
                text={
                  expiresAt ? humanizeDateTime(expiresAt) : 'Default - 1 year'
                }
                minimal
                small
              />
            </Popover2>
          </div>
          <div className="mini-field os-type-selector">
            <div
              className="mini-field-header"
              title="Choose the OS you would like the voucher to be compatible with"
            >
              <Icon icon="console" />
              OS Type
            </div>
            <ButtonGroup
              className="os-type-selector-button-group"
              title={
                pkCertificate
                  ? 'OS Type will always be IOS XR when the Platform Key Certificate is provided'
                  : null
              }
            >
              {Object.entries(OS_TYPES).map(([_, os]) => (
                <Button
                  intent={os === osType ? 'primary' : 'default'}
                  key={os}
                  onClick={() => osTypeFieldChanged(os)}
                  active={os === osType}
                  disabled={pkCertificate || securityProfile}
                  small
                >
                  {OS_TYPES_DISPLAY_MAP[os]}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <div className="mini-field override-selector">
            <div
              className="mini-field-header"
              title="Enable this toggle to automatically archive any existing vouchers and generate new vouchers"
            >
              <Icon icon="resolve" />
              Override
            </div>
            <Switch
              className="override-selector-toggle"
              checked={override}
              onChange={overrideFieldToggled}
              label={override ? 'ON' : 'OFF'}
            />
          </div>
          <div className="mini-field security-profile-selector">
            <div
              className="mini-field-header"
              title="Enable this toggle to include a security profile in the voucher"
            >
              <Icon icon="form" />
              Security profile
            </div>
            <Switch
              className="security-profile-selector-toggle"
              checked={securityProfile}
              onChange={securityProfileFieldToggled}
              label={securityProfile ? 'ON' : 'OFF'}
              disabled={osType !== OS_TYPES.IOS_XR || pkCertificate}
            />
          </div>
          {securityProfile && <SecurityProfileSelector />}
        </div>
      </div>
      <div className="request-form-footer">
        <Button
          onClick={onSubmit}
          className="submit-button"
          text="Request"
          intent="primary"
          icon="send-to"
          loading={loading}
          disabled={
            isProcessing ||
            loading ||
            !serialNumbers.length ||
            (usePreUploadedCertificate ? !selectedCertificate : !certificate)
          }
        />
      </div>
    </div>
  )
}

export default RequestFormView
