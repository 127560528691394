import './styles.scss'
import Row from './Row'

import { Button, Checkbox, NonIdealState, Spinner } from '@blueprintjs/core'
import classNames from 'classnames'

const Table = ({
  loading,
  error,
  items,
  refetch,
  archiveRequest,
  archiveVoucher,
  downloadVoucher,
  selectVoucher,
  deselectVoucher,
  selectedVouchers,
  selectAllVouchers,
  deselectAllVouchers,
  singleRequestMode,
  relatedVouchersMode
}) => {
  const selectableItems = items?.filter((item) => item.status === 'completed')
  const isAllSelected =
    selectableItems?.length > 0 &&
    selectableItems.every((item) => selectedVouchers?.[item.voucher_id])

  return (
    <div
      className={classNames('table-container', {
        error,
        'secondary-loading': loading && items?.length,
        'initial-loading': loading && !items?.length,
        'single-request-mode': singleRequestMode,
        'related-vouchers-mode': relatedVouchersMode
      })}
    >
      {loading && (
        <Spinner
          className="loading-spinner animate__animated animate__fadeIn animate__faster"
          size={24}
        />
      )}
      {!loading && error && (
        <NonIdealState
          className="load-failed animate__animated animate__fadeIn animate__fast"
          title={`Failed to load ${
            relatedVouchersMode ? 'related ' : ''
          }vouchers`}
          description={error?.toString()}
          icon="error"
          action={
            <Button
              onClick={refetch}
              intent="primary"
              icon="repeat"
              text="Reload"
            />
          }
        />
      )}
      {!loading && !error && !items?.length && (
        <NonIdealState
          className="no-items-found animate__animated animate__fadeIn animate__fast"
          title={`No ${relatedVouchersMode ? 'related ' : ''}vouchers found`}
          icon={relatedVouchersMode ? 'duplicate' : 'circle'}
        />
      )}
      <table className="table">
        <thead>
          <tr>
            {!relatedVouchersMode && (
              <th className="selection">
                <Checkbox
                  disabled={!selectableItems?.length}
                  checked={isAllSelected}
                  onChange={() =>
                    isAllSelected
                      ? deselectAllVouchers(selectableItems)
                      : selectAllVouchers(selectableItems)
                  }
                />
              </th>
            )}
            {!relatedVouchersMode && (
              <th className="serial-number">Serial Number</th>
            )}
            {!singleRequestMode && (
              <>
                <th className="requested-by">Requested By</th>
                <th className="requested-at">Requested</th>
              </>
            )}
            {singleRequestMode && <th className="created-at">Created</th>}
            <th className="expires-at">Expires</th>
            <th className="assertion">Assertion</th>
            <th className="status">Status</th>
            {!singleRequestMode && <th className="request-id">Request ID</th>}
            <th className="voucher-id">Voucher ID</th>
            {!singleRequestMode && !relatedVouchersMode && (
              <th className="pdc-organization">PDC Organization</th>
            )}
            {relatedVouchersMode && <th className="os-type">OS Type</th>}
            <th className="actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item, i) => (
            <Row
              key={`item-${item.req_id}-${item.voucher_id}-${i}`}
              reqID={item.req_id}
              voucherID={item.voucher_id}
              requestedAt={item.requested_at}
              createdAt={item.created_at}
              expiresAt={item.expires_at}
              assertion={item.assertion}
              status={item.status}
              serialNumber={item.serial_number}
              pdcOrganization={item.pdc_organization}
              osType={item.os_type}
              requestedBy={item.requested_by}
              downloadVoucher={downloadVoucher}
              archiveRequest={archiveRequest}
              archiveVoucher={archiveVoucher}
              isSelected={selectedVouchers?.[item.voucher_id] ?? false}
              checkboxChanged={
                selectedVouchers?.[item.voucher_id]
                  ? deselectVoucher
                  : selectVoucher
              }
              singleRequestMode={singleRequestMode}
              relatedVouchersMode={relatedVouchersMode}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
