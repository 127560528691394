import Toast from '../shared/Toast'
import { pluralize } from '../shared/utils'

import _ from 'lodash'
import download from 'downloadjs'
import { getDownloadMeta } from '../../utils'

export const VOUCHER_DETAIL_REQUESTED = 'VOUCHER_DETAIL_REQUESTED'
export const VOUCHER_DETAIL_REQUEST_SUCCESS = 'VOUCHER_DETAIL_REQUEST_SUCCESS'
export const VOUCHER_DETAIL_REQUEST_FAILURE = 'VOUCHER_DETAIL_REQUEST_FAILURE'
export const VOUCHER_DOWNLOAD_REQUESTED = 'VOUCHER_DOWNLOAD_REQUESTED'
export const VOUCHER_DOWNLOAD_SUCCESS = 'VOUCHER_DOWNLOAD_SUCCESS'
export const VOUCHER_DOWNLOAD_FAILURE = 'VOUCHER_DOWNLOAD_FAILURE'
export const VOUCHER_ARCHIVE_REQUESTED = 'VOUCHER_ARCHIVE_REQUESTED'
export const VOUCHER_ARCHIVE_SUCCESS = 'VOUCHER_ARCHIVE_SUCCESS'
export const VOUCHER_ARCHIVE_FAILURE = 'VOUCHER_ARCHIVE_FAILURE'
export const VOUCHER_BULK_ARCHIVE_REQUESTED = 'VOUCHER_BULK_ARCHIVE_REQUESTED'
export const VOUCHER_BULK_ARCHIVE_SUCCESS = 'VOUCHER_BULK_ARCHIVE_SUCCESS'
export const VOUCHER_BULK_ARCHIVE_FAILURE = 'VOUCHER_BULK_ARCHIVE_FAILURE'
export const VOUCHER_BULK_DOWNLOAD_REQUESTED = 'VOUCHER_BULK_DOWNLOAD_REQUESTED'
export const VOUCHER_BULK_DOWNLOAD_SUCCESS = 'VOUCHER_BULK_DOWNLOAD_SUCCESS'
export const VOUCHER_BULK_DOWNLOAD_FAILURE = 'VOUCHER_BULK_DOWNLOAD_FAILURE'
export const VOUCHER_SELECTED = 'VOUCHER_SELECTED'
export const VOUCHER_DESELECTED = 'VOUCHER_DESELECTED'
export const VOUCHER_MULTI_SELECTED = 'VOUCHER_MULTI_SELECTED'
export const VOUCHER_MULTI_DESELECTED = 'VOUCHER_MULTI_DESELECTED'
export const RESET_VOUCHER_SELECTION = 'RESET_VOUCHER_SELECTION'

const voucherDetailRequested = () => {
  return {
    type: VOUCHER_DETAIL_REQUESTED
  }
}

const voucherDetailRequestSuccess = (voucher) => {
  return {
    type: VOUCHER_DETAIL_REQUEST_SUCCESS,
    voucher
  }
}

const voucherDetailRequestFailure = (id, error) => {
  return {
    type: VOUCHER_DETAIL_REQUEST_FAILURE,
    id,
    error
  }
}

const voucherDownloadRequested = () => {
  return {
    type: VOUCHER_DOWNLOAD_REQUESTED
  }
}

const voucherDownloadSuccess = (id) => {
  return {
    type: VOUCHER_DOWNLOAD_SUCCESS,
    id
  }
}

const voucherDownloadFailure = (id, error) => {
  return {
    type: VOUCHER_DOWNLOAD_FAILURE,
    id,
    error
  }
}

const voucherArchiveRequested = (id) => {
  return {
    type: VOUCHER_ARCHIVE_REQUESTED,
    id
  }
}

const voucherArchiveSuccess = (id) => {
  return {
    type: VOUCHER_ARCHIVE_SUCCESS,
    id
  }
}

const voucherArchiveFailure = (id, error) => {
  return {
    type: VOUCHER_ARCHIVE_FAILURE,
    id,
    error
  }
}

const voucherBulkArchiveRequested = (vouchers) => {
  return {
    type: VOUCHER_BULK_ARCHIVE_REQUESTED,
    vouchers
  }
}

const voucherBulkArchiveSuccess = (vouchers, isPartial) => {
  return {
    type: VOUCHER_BULK_ARCHIVE_SUCCESS,
    vouchers,
    isPartial
  }
}

const voucherBulkArchiveFailure = (vouchers, error) => {
  return {
    type: VOUCHER_BULK_ARCHIVE_FAILURE,
    vouchers,
    error
  }
}

const voucherBulkDownloadRequested = () => {
  return {
    type: VOUCHER_BULK_ARCHIVE_REQUESTED
  }
}

const voucherBulkDownloadSuccess = (vouchers) => {
  return {
    type: VOUCHER_BULK_DOWNLOAD_SUCCESS,
    vouchers
  }
}

const voucherBulkDownloadFailure = (vouchers, error) => {
  return {
    type: VOUCHER_BULK_DOWNLOAD_FAILURE,
    vouchers,
    error
  }
}

export const voucherSelected = (id) => {
  return {
    type: VOUCHER_SELECTED,
    id
  }
}

export const voucherDeselected = (id) => {
  return {
    type: VOUCHER_DESELECTED,
    id
  }
}

export const voucherMultiSelected = (vouchers) => {
  return {
    type: VOUCHER_MULTI_SELECTED,
    vouchers
  }
}

export const voucherMultiDeselected = (vouchers) => {
  return {
    type: VOUCHER_MULTI_DESELECTED,
    vouchers
  }
}

export const resetVoucherSelection = () => {
  return {
    type: RESET_VOUCHER_SELECTION
  }
}

export const fetchVoucherDetail = (id) => async (dispatch) => {
  dispatch(voucherDetailRequested())
  try {
    const response = await fetch(`/api/voucher/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(voucherDetailRequestSuccess(json.voucher))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(voucherDetailRequestFailure(id, e))
    Toast.show({
      message: `Failed to fetch voucher data ${id} - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(fetchVoucherDetail(id))
      }
    })
  }
}

export const downloadVoucher = (id, serialNumber) => async (dispatch) => {
  dispatch(voucherDownloadRequested())
  try {
    const response = await fetch(`/api/download/voucher/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (response.ok) {
      const blob = await response.blob()
      const { fileName, mimeType } = getDownloadMeta(response, blob)
      download(blob, fileName, mimeType)
      dispatch(voucherDownloadSuccess(id))
    } else {
      const json = await response.json()
      throw new Error(json.error.msg)
    }
  } catch (e) {
    dispatch(voucherDownloadFailure(id, e))
    Toast.show({
      message: `Failed to download voucher ${id} - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(downloadVoucher(id, serialNumber))
      }
    })
  }
}

export const archiveVoucher =
  (id, redirect = false, navigate = null) =>
  async (dispatch) => {
    dispatch(voucherArchiveRequested(id))
    try {
      const response = await fetch(`/api/voucher/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const json = await response.json()
      if (json.ok) {
        dispatch(voucherArchiveSuccess(id))
        Toast.show({
          message: `Successfully archived voucher ${id}`,
          intent: 'success'
        })
        if (redirect) navigate('/home')
      } else throw new Error(json.error.msg)
    } catch (e) {
      dispatch(voucherArchiveFailure(id, e))
      Toast.show({
        message: `Failed to archive voucher ${id} - ${e}`,
        intent: 'danger',
        action: {
          text: 'Try again',
          icon: 'repeat',
          onClick: () => dispatch(archiveVoucher(id))
        }
      })
    }
  }

export const bulkArchiveVouchers = (vouchers) => async (dispatch) => {
  dispatch(voucherBulkArchiveRequested(vouchers))
  try {
    const response = await fetch('/api/archive/vouchers', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vouchers })
    })
    const json = await response.json()
    if (json.ok) {
      const archivedVouchers = json.archived
      const isPartial = archivedVouchers.length !== vouchers.length
      if (isPartial) {
        const remainingVouchers = _.difference(vouchers, archivedVouchers)
        Toast.show({
          message: `Failed to archive ${remainingVouchers.length} of ${vouchers.length} vouchers`,
          intent: 'danger',
          action: {
            text: 'Try again',
            icon: 'repeat',
            onClick: () => dispatch(bulkArchiveVouchers(remainingVouchers))
          }
        })
      } else {
        Toast.show({
          message: `${pluralize({
            word: 'voucher',
            count: vouchers.length
          })} archived successfully`,
          intent: 'success'
        })
      }
      dispatch(voucherBulkArchiveSuccess(archivedVouchers, isPartial))
      dispatch(resetVoucherSelection())
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(voucherBulkArchiveFailure(vouchers, e))
    Toast.show({
      message: `Failed to archive vouchers - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(bulkArchiveVouchers(vouchers))
      }
    })
  }
}

export const bulkDownloadVouchers = (vouchers) => async (dispatch) => {
  dispatch(voucherBulkDownloadRequested(vouchers))
  try {
    const response = await fetch('/api/download/vouchers', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ vouchers })
    })
    if (response.ok) {
      const blob = await response.blob()
      const { fileName, mimeType } = getDownloadMeta(response, blob)
      download(blob, fileName, mimeType)
      dispatch(voucherBulkDownloadSuccess(vouchers))
      dispatch(resetVoucherSelection())
    } else {
      const json = await response.json()
      throw new Error(json.error.msg)
    }
  } catch (e) {
    dispatch(voucherBulkDownloadFailure(vouchers, e))
    Toast.show({
      message: `Failed to download vouchers - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(bulkDownloadVouchers(vouchers))
      }
    })
  }
}
