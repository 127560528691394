import Cookies from 'universal-cookie'

export const readCookies = () => {
  const cookies = new Cookies()
  const user = Boolean(Number(cookies.get('masa_user')))
  const consented = Boolean(Number(cookies.get('masa_uca')))

  return {
    user,
    consented
  }
}

export const removeCookies = () => {
  const cookies = new Cookies()
  const allCookies = cookies.getAll()

  Object.keys(allCookies).forEach((cookie) => {
    cookies.remove(cookie, { path: '/' })

    if (process.env.NODE_ENV === 'production')
      cookies.remove(cookie, { path: '/', domain: '.cisco.com' })
  })
}

export const resetUserSession = (timeout = 0) => {
  setTimeout(() => {
    removeCookies()
    window.location.reload()
  }, timeout)
}

export const getDownloadMeta = (response, blob) => {
  const contentHeader = response.headers.get('content-disposition')
  let fileName = contentHeader?.split('filename=')[1] ?? 'unknown'
  fileName = fileName.replace(/"/g, '')

  return {
    fileName,
    mimeType: blob.type
  }
}
