import { Button, Classes } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

const NavPortal = ({ children }) => {
  const [navActions, setNavActions] = useState(null)

  useEffect(() => {
    setNavActions(document.getElementById('nav-actions'))
  }, [])

  return navActions
    ? createPortal(
        <>
          <div className="nav-actions-desktop">{children}</div>
          {children?.props?.children && (
            <Popover2
              content={children}
              className="nav-actions-mobile"
              popoverClassName={classNames('nav-actions-popover', {
                [Classes.POPOVER_DISMISS]: true
              })}
              placement="bottom-start"
              rootBoundary="viewport"
            >
              <Button
                className="nav-actions-mobile-target"
                icon="more"
                minimal
              />
            </Popover2>
          )}
        </>,
        navActions
      )
    : null
}

export default NavPortal
