import {
  MY_ORGANIZATIONS_REQUESTED,
  MY_ORGANIZATIONS_REQUEST_SUCCESS,
  MY_ORGANIZATIONS_REQUEST_FAILURE,
  ORGANIZATION_SWITCH_REQUESTED,
  ORGANIZATION_SWITCH_SUCCESS,
  ORGANIZATION_SWITCH_FAILURE
} from './actions'

const defaultState = {
  organizations: [],
  currentOrganization: {},
  meta: {}
}

const orgSwitcher = (state = defaultState, action) => {
  switch (action.type) {
    case MY_ORGANIZATIONS_REQUESTED:
      return {
        ...state,
        loading: true
      }
    case MY_ORGANIZATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: action.organizations,
        currentOrganization: action.currentOrganization,
        meta: action.meta
      }
    case MY_ORGANIZATIONS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false
      }
    case ORGANIZATION_SWITCH_REQUESTED:
      return {
        ...state,
        loading: true
      }
    case ORGANIZATION_SWITCH_SUCCESS:
      return {
        ...state,
        loading: false,
        currentOrganization: action.currentOrganization
      }
    case ORGANIZATION_SWITCH_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
export default orgSwitcher
