import './styles.scss'
import { humanizeDateTime, humanizeDuration } from '../utils'

import { Tag } from '@blueprintjs/core'
import Moment from 'moment'

export const DateValue = ({ date, alt = '-' }) =>
  date ? humanizeDateTime(date) : alt

export const TimeDiff = ({ date }) => {
  if (date) {
    let prefix = ''
    let suffix = ''
    let diff
    if (Moment().isBefore(date)) {
      prefix = 'in'
      diff = humanizeDuration(Moment(date).diff(Moment.now(), 'minute'))
    } else {
      suffix = 'ago'
      diff = humanizeDuration(Moment().diff(date, 'minute'))
    }
    return `${prefix} ${diff} ${suffix}`
  } else return null
}

export const Assertion = ({ assertion }) =>
  assertion ? (
    <Tag
      className="renderer-assertion"
      icon={assertion === 'verified' ? 'endorsed' : 'database'}
      intent={assertion === 'verified' ? 'success' : 'primary'}
      minimal
    >
      {assertion}
    </Tag>
  ) : (
    '-'
  )

export const Status = ({ status }) => {
  const iconMap = {
    requested: 'cloud-upload',
    completed: 'tick',
    failed: 'error',
    archived: 'archive'
  }
  const intentMap = {
    requested: 'primary',
    completed: 'success',
    failed: 'warning',
    archived: 'danger'
  }
  return (
    <Tag
      className="renderer-status"
      intent={intentMap[status] ?? 'default'}
      icon={iconMap[status] ?? 'help'}
      minimal
    >
      {status}
    </Tag>
  )
}
