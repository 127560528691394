export const SECURITY_PROFILES = Object.freeze({
  SZTP: 'sztp',
  TPRPM_SIG_CHECK: 'tprpm_sig_check',
  PARTNER_RPM_GISO: 'partner_rpm_giso'
})

export const SECURITY_PROFILES_DISPLAY_MAP = Object.freeze({
  [SECURITY_PROFILES.SZTP]: 'Secure ZTP',
  [SECURITY_PROFILES.TPRPM_SIG_CHECK]: 'Third Party RPM Signature Check',
  [SECURITY_PROFILES.PARTNER_RPM_GISO]: 'Partner RPM GISO'
})

export const SECURITY_PROFILE_MODES = Object.freeze({
  DEFAULT: 'default',
  STRICT: 'strict',
  RELAXED: 'relaxed',
  CUSTOM: 'custom'
})
