import UnauthenticatedHeaderView from './UnauthenticatedHeaderView'

import { useSelector } from 'react-redux'

const UnauthenticatedHeader = ({ title }) => {
  const { user } = useSelector((state) => state.app)

  return user ? null : <UnauthenticatedHeaderView title={title} />
}

export default UnauthenticatedHeader
