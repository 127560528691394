import Toast from '../../shared/Toast'

export const REQUESTS_SEARCH_CHANGED = 'REQUESTS_SEARCH_CHANGED'
export const REQUESTS_SORT_ORDER_CHANGED = 'REQUESTS_SORT_ORDER_CHANGED'
export const REQUESTS_SORT_TYPE_CHANGED = 'REQUESTS_SORT_TYPE_CHANGED'
export const REQUESTS_DATE_RANGE_CHANGED = 'REQUESTS_DATE_RANGE_CHANGED'
export const PDC_ORGANIZATIONS_REQUESTED = 'PDC_ORGANIZATIONS_REQUESTED'
export const PDC_ORGANIZATIONS_REQUEST_SUCCESS =
  'PDC_ORGANIZATIONS_REQUEST_SUCCESS'
export const PDC_ORGANIZATIONS_REQUEST_FAILURE =
  'PDC_ORGANIZATIONS_REQUEST_FAILURE'
export const PDC_ORGANIZATION_FILTER_CHANGED = 'PDC_ORGANIZATION_FILTER_CHANGED'
export const STATUS_FILTER_CHANGED = 'STATUS_FILTER_CHANGED'
export const CLEAR_STATUS_FILTER = 'CLEAR_STATUS_FILTER'
export const RESET_FILTER_DATA = 'RESET_FILTER_DATA'

export const searchChanged = (search) => {
  return {
    type: REQUESTS_SEARCH_CHANGED,
    search
  }
}

export const sortOrderChanged = (sortOrder) => {
  return {
    type: REQUESTS_SORT_ORDER_CHANGED,
    sortOrder
  }
}

export const sortTypeChanged = (sortType) => {
  return {
    type: REQUESTS_SORT_TYPE_CHANGED,
    sortType
  }
}

export const dateRangeChanged = (selectedDateRange) => {
  return {
    type: REQUESTS_DATE_RANGE_CHANGED,
    selectedDateRange
  }
}

const pdcOrganizationsRequested = () => {
  return {
    type: PDC_ORGANIZATIONS_REQUESTED
  }
}

const pdcOrganizationsRequestSuccess = (pdcOrganizations) => {
  return {
    type: PDC_ORGANIZATIONS_REQUEST_SUCCESS,
    pdcOrganizations
  }
}

const pdcOrganizationsRequestFailure = (error) => {
  return {
    type: PDC_ORGANIZATIONS_REQUEST_FAILURE,
    error
  }
}

export const pdcOrganizationFilterChanged = (selectedPDCOrganization) => {
  return {
    type: PDC_ORGANIZATION_FILTER_CHANGED,
    selectedPDCOrganization
  }
}

export const statusFilterChanged = (selectedStatus) => {
  return {
    type: STATUS_FILTER_CHANGED,
    selectedStatus
  }
}

export const clearStatusFilter = () => {
  return {
    type: CLEAR_STATUS_FILTER
  }
}

export const resetFilterData = () => {
  return {
    type: RESET_FILTER_DATA
  }
}

export const fetchPDCOrganizations = () => async (dispatch, getState) => {
  dispatch(pdcOrganizationsRequested())
  try {
    const response = await fetch('/api/pdc-organizations', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(pdcOrganizationsRequestSuccess(json.pdc_organizations))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(pdcOrganizationsRequestFailure(e))
    Toast.show({
      message: `Failed to fetch PDC Organizations - ${e}`,
      intent: 'danger'
    })
  }
}
