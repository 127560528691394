import {
  RELEASES_REQUESTED,
  RELEASES_REQUEST_SUCCESS,
  RELEASES_REQUEST_FAILURE
} from './actions'

const defaultState = {}

const releaseNotes = (state = defaultState, action) => {
  switch (action.type) {
    case RELEASES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case RELEASES_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        releases: action.releases
      }
    case RELEASES_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}
export default releaseNotes
