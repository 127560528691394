export const SortTypes = Object.freeze({
  SERIAL_NUMBER: 'Serial Number',
  REQUESTED_BY: 'Requested By',
  DATE_REQUESTED: 'Date Requested',
  DATE_CREATED: 'Date Created',
  DATE_EXPIRES: 'Expiry Date',
  ASSERTION: 'Assertion',
  STATUS: 'Status',
  PDC_ORGANIZATION: 'PDC Organization'
})

export const SortMap = Object.freeze({
  [SortTypes.SERIAL_NUMBER]: 'serial_number',
  [SortTypes.REQUESTED_BY]: 'requested_by',
  [SortTypes.DATE_REQUESTED]: 'requested_at',
  [SortTypes.DATE_CREATED]: 'created_at',
  [SortTypes.DATE_EXPIRES]: 'expires_at',
  [SortTypes.ASSERTION]: 'assertion',
  [SortTypes.STATUS]: 'status',
  [SortTypes.PDC_ORGANIZATION]: 'pdc_organization'
})

export const StatusFilterTypes = Object.freeze({
  IS_ACTIVE: 'Active',
  IS_EXPIRED: 'Expired',
  ASSERTION_LOGGED: 'Logged',
  ASSERTION_VERIFIED: 'Verified',
  STATUS_COMPLETED: 'Completed',
  STATUS_FAILED: 'Failed',
  STATUS_ARCHIVED: 'Archived'
})

export const StatusFilterMap = Object.freeze({
  [StatusFilterTypes.IS_ACTIVE]: 'active',
  [StatusFilterTypes.IS_EXPIRED]: 'expired',
  [StatusFilterTypes.ASSERTION_LOGGED]: 'logged',
  [StatusFilterTypes.ASSERTION_VERIFIED]: 'verified',
  [StatusFilterTypes.STATUS_COMPLETED]: 'completed',
  [StatusFilterTypes.STATUS_FAILED]: 'failed',
  [StatusFilterTypes.STATUS_ARCHIVED]: 'archived'
})
