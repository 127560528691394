import './styles.scss'
import OrgSwitcher from '../OrgSwitcher'

import { Link } from 'react-router-dom'
import { Icon, Button, Spinner, Tag } from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'

const MenuItem = ({ title, icon, link, onClick, className }) => {
  return (
    <Tooltip2
      className={`menu-item ${className}`}
      popoverClassName="menu-item-popover"
      rootBoundary="viewport"
      modifiers={{
        arrow: {
          enabled: false
        }
      }}
      content={<div>{title}</div>}
    >
      {link ? (
        <Link to={link} onClick={onClick} className="menu-item-link">
          <Icon icon={icon} />
        </Link>
      ) : (
        <Icon icon={icon} onClick={onClick} className="menu-item-link" />
      )}
    </Tooltip2>
  )
}

const MenuView = ({
  logoutClicked,
  releasesLoading,
  releasesLoadingError,
  currentRelease
}) => {
  return (
    <div className="menu-container">
      <Link to="/home" className="logo" />
      <MenuItem title="Home" icon="home" link="/home" />
      <MenuItem title="API Docs" icon="console" link="/docs" />
      <div className="secondary-section">
        <OrgSwitcher />
        <MenuItem title="Settings" icon="settings" link="/settings" />
        <Popover2
          popoverClassName="logout-popover"
          hasBackdrop
          modifiers={{
            arrow: {
              enabled: false
            }
          }}
          content={
            <Button
              onClick={logoutClicked}
              text="Confirm Logout"
              icon="tick"
              intent="danger"
            />
          }
        >
          <MenuItem title="Logout" icon="log-out" />
        </Popover2>
        <Link
          to="/releases"
          className="menu-item-version"
          title="Release Notes"
        >
          <Tag intent="warning" round interactive>
            {releasesLoading && <Spinner size={12} intent="warning" />}
            {!releasesLoading && releasesLoadingError && (
              <Icon icon="tag" size={10} />
            )}
            {!releasesLoading && !releasesLoadingError && (
              <span className="animate__animated animate__fadeIn animate__fastest">
                v{currentRelease?.version}
              </span>
            )}
          </Tag>
        </Link>
      </div>
    </div>
  )
}

export default MenuView
