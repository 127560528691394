import './App.scss'
import { loginSuccess } from './components/Login/actions'
import { fetchAlerts, fetchAvailablePermissions } from './actions'
import { fetchReleases } from './components/ReleaseNotes/actions'

// Components
import Landing from './components/Landing'
import UserAgreement from './components/UserAgreement'
import AuthGuard from './components/AuthGuard'
import SSOFailure from './components/Login/SSOFailure'

import { useCallback, useEffect, lazy, Suspense } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from '@blueprintjs/core'
import { readCookies } from './utils'

// Lazy Loaded components
const Documentation = lazy(() => import('./components/Documentation'))
const ReleaseNotes = lazy(() => import('./components/ReleaseNotes'))

const App = () => {
  const { user, permissions, consented } = useSelector((state) => state.app)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isLanding = useCallback(
    (pathname) => {
      const landingPages = ['/', '/landing', '/docs', '/releases']
      return (
        landingPages.includes(pathname) ||
        (!user && pathname.includes('/login-failed'))
      )
    },
    [user]
  )

  useEffect(() => {
    const { user, consented } = readCookies()
    const { pathname, search, hash } = window.location

    if (user) {
      if (!consented) {
        navigate('/user-agreement')
        return
      }
      dispatch(loginSuccess(user, consented))
      if (!permissions) dispatch(fetchAvailablePermissions())
      const redirectURL = localStorage.getItem('redirectURL')

      if (redirectURL) {
        try {
          navigate(redirectURL)
        } catch {
          navigate('/home')
        }
        localStorage.removeItem('redirectURL')
      } else if (pathname === '/') {
        navigate('/home')
      }
    } else {
      if (!isLanding(pathname)) {
        localStorage.setItem('redirectURL', `${pathname}${search}${hash}`)
        navigate('/')
      }
    }

    // Cookie detection fix for SSO based authentication
    const searchParams = new URLSearchParams(search)

    if (searchParams.has('refresh')) {
      searchParams.delete('refresh')

      let params = searchParams.toString()
      if (params) params = `?${params}`
      const url = `${pathname}${params}${hash}`

      window.location.replace(url)
    }
  }, [dispatch, navigate, isLanding, permissions])

  useEffect(() => {
    dispatch(fetchAlerts())
    dispatch(fetchReleases())
  }, [dispatch])

  return (
    <Suspense fallback={<Spinner className="spinner-position" />}>
      <Routes>
        <Route path="/" element={<Landing />} />
        {!user && <Route path="/docs" element={<Documentation />} />}
        {!user && <Route path="/releases" element={<ReleaseNotes />} />}
        {!consented && (
          <Route path="/user-agreement" element={<UserAgreement />} />
        )}
        {!user && <Route path="/login-failed" element={<SSOFailure />} />}
        {user && <Route path="/*" element={<AuthGuard />} />}
      </Routes>
    </Suspense>
  )
}

export default App
