// React imports
import React from 'react'
import ReactDOM from 'react-dom/client'

// Third-party Style imports
import 'normalize.css'
import 'animate.css/animate.min.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'

// Style imports
import 'stencil/styles.scss'
import './index.scss'

// Redux imports
import { Provider } from 'react-redux'
import { configureStore } from './store'

// App imports
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { FocusStyleManager } from '@blueprintjs/core'
import fetchIntercept from 'fetch-intercept'
import reportWebVitals from './reportWebVitals'
import { resetUserSession } from './utils'
import Toast from './components/shared/Toast'

// Setup Redux store
let store = configureStore()

// Remove focus outline from Blueprint elements
FocusStyleManager.onlyShowFocusOnTabs()

// Common handler for all fetch calls
fetchIntercept.register({
  response: async function (response) {
    if (response.status === 401) {
      const json = await response.clone().json()
      if (json.error?.type === 'login_required') {
        Toast.clear()
        Toast.show({
          message: 'Your session has expired. Please login again',
          intent: 'primary'
        })
        resetUserSession(3000)
      }
      if (json.error?.type === 'user_agreement_error') {
        resetUserSession()
      }
    }
    if (response.status === 504) {
      let newResponse = new Response(
        JSON.stringify({ error: { msg: 'Unable to reach MASA service' } }),
        {
          status: 504
        }
      )
      return newResponse
    }
    return response
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
