import './styles.scss'
import Selector from '../Selector'

import { Icon } from '@blueprintjs/core'
import classNames from 'classnames'

const SortBy = ({
  types,
  selectedType,
  typeChanged,
  desc,
  orderChanged,
  disabled
}) => {
  return (
    <div className="sort-by-container">
      <Icon
        className={classNames('sort-by-order', {
          disabled
        })}
        icon={desc ? 'sort-desc' : 'sort-asc'}
        onClick={() => (disabled ? undefined : orderChanged(!desc))}
      />
      <Selector
        id="sort"
        name="Sort"
        items={types}
        onItemSelect={(_, type) => typeChanged(type)}
        selectedItems={selectedType}
        disabled={disabled}
      />
    </div>
  )
}

export default SortBy
