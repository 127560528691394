import { pluralize } from '../utils'

import { Tag } from '@blueprintjs/core'
import classNames from 'classnames'

const SearchBar = ({
  searchChanged,
  className,
  name,
  value,
  defaultValue,
  placeholder,
  onKeyUp,
  small,
  count,
  countLabel = 'item',
  pluralSuffix,
  disabled
}) => {
  return (
    <div
      className={classNames('search-bar', {
        [className]: className
      })}
    >
      <input
        className={classNames('search-bar-input', {
          small
        })}
        type="search"
        name={name}
        placeholder={placeholder ?? 'Search'}
        onChange={searchChanged}
        onKeyUp={onKeyUp}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      {!isNaN(count) && count > 0 && (
        <Tag
          className="search-bar-count animated zoomIn animate__fastest"
          round
        >
          {pluralize({ word: countLabel, count, pluralAdditive: pluralSuffix })}
        </Tag>
      )}
    </div>
  )
}

export default SearchBar
