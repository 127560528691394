export const SET_NAV_TITLE = 'SET_NAV_TITLE'

export const setNavTitle = (title, subTitle) => {
  // Set Document Title
  let documentTitle = ''
  title
    .slice()
    .reverse()
    .forEach((item) => {
      if (item.text && typeof item.text === 'string' && item.text !== 'MASA')
        documentTitle = documentTitle + `${item.text} · `
    })
  document.title = documentTitle + 'MASA'

  return {
    type: SET_NAV_TITLE,
    title,
    subTitle
  }
}
