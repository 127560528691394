import './styles.scss'
import HomeActions from './HomeActions'
import RequestForm from '../RequestForm'
import Requests from '../Requests'

import { useState } from 'react'
import { Classes, Dialog } from '@blueprintjs/core'

const HomeView = ({ canClose, resetRequestForm }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="home-container">
      <HomeActions openRequestForm={() => setIsOpen(true)} />
      <Requests />
      <Dialog
        title="New Request"
        className="request-form-dialog"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
          resetRequestForm()
        }}
        canEscapeKeyClose={canClose}
        canOutsideClickClose={canClose}
        isCloseButtonShown={true}
      >
        <div className={Classes.DIALOG_BODY}>
          <RequestForm callback={() => setIsOpen(false)} />
        </div>
      </Dialog>
    </div>
  )
}

export default HomeView
