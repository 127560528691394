import {
  RELATED_VOUCHERS_REQUESTED,
  RELATED_VOUCHERS_REQUEST_FAILURE,
  RELATED_VOUCHERS_REQUEST_SUCCESS
} from './actions'

const defaultState = {}

const relatedVouchers = (state = defaultState, action) => {
  switch (action.type) {
    case RELATED_VOUCHERS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case RELATED_VOUCHERS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        vouchers: action.vouchers
      }
    case RELATED_VOUCHERS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}
export default relatedVouchers
