import {
  USER_SEARCH_QUERY_CHANGED,
  USER_SEARCH_USERS_REQUEST_SUCCESS,
  USER_SEARCH_USERS_REQUEST_FAILURE,
  USER_SEARCH_USER_DESELECTED,
  USER_SEARCH_USER_SELECTED,
  USER_SEARCH_USERS_REQUESTED,
  RESET_USER_SEARCH
} from './actions'

const defaultState = { users: [], selectedUsers: [] }

const userSearch = (state = defaultState, action) => {
  switch (action.type) {
    case USER_SEARCH_QUERY_CHANGED:
      return {
        ...state,
        query: action.query,
        users: []
      }
    case USER_SEARCH_USERS_REQUESTED:
      return {
        ...state,
        loading: true
      }
    case USER_SEARCH_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users
      }
    case USER_SEARCH_USERS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        users: []
      }
    case USER_SEARCH_USER_SELECTED:
      return {
        ...state,
        selectedUsers: [...state.selectedUsers, action.user]
      }
    case USER_SEARCH_USER_DESELECTED:
      return {
        ...state,
        selectedUsers: state.selectedUsers.filter(
          (user) => user.user_id !== action.user.user_id
        )
      }
    case RESET_USER_SEARCH:
      return defaultState
    default:
      return state
  }
}

export default userSearch
