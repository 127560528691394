import './styles.scss'

import { Button, AnchorButton, Tag } from '@blueprintjs/core'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

const LoginView = ({ token, setToken, loginClicked, loading }) => {
  const state = uuidv4()
  return (
    <div className="login-container">
      {process.env.REACT_APP_SSO_SERVER && (
        <div className="sso-login-container animate__animated animate__pulse animate__faster">
          <div
            className={classNames('sso-login', {
              hidden: token
            })}
          >
            <AnchorButton
              className="login-button"
              icon="log-in"
              href={`https://${process.env.REACT_APP_SSO_SERVER}/oauth2/default/v1/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=openid%20profile%20email&state=${state}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`}
              text="Sign In using Cisco SSO"
              intent="primary"
              minimal
              disabled={token}
              large
            />
            {process.env.REACT_APP_ENABLE_TOKEN_LOGIN && (
              <Tag
                round
                minimal
                className="or animate__animated animate__pulse"
              >
                OR
              </Tag>
            )}
          </div>
        </div>
      )}
      {process.env.REACT_APP_ENABLE_TOKEN_LOGIN && (
        <div className="token-login animate__animated animate__zoomIn animate__faster">
          <input
            className={classNames('login-input', {
              expanded: token
            })}
            name="token"
            placeholder="Token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <Button
            onClick={loginClicked}
            disabled={!token || loading}
            className="login-button"
            intent="success"
            minimal
          >
            Login
          </Button>
        </div>
      )}
    </div>
  )
}

export default LoginView
