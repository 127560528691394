const CERTIFICATE_FILE_TYPES = ['.txt', '.pem', '.cer', '.crt']
const SERIAL_NUMBERS_FILE_TYPES = ['.txt', '.csv']

export const allowedFileTypes = Object.freeze({
  certificate: CERTIFICATE_FILE_TYPES,
  serialNumbers: SERIAL_NUMBERS_FILE_TYPES,
  pkCertificate: CERTIFICATE_FILE_TYPES
})

export const OS_TYPES = Object.freeze({
  IOS_XR: 'IOSXR',
  IOS_XE: 'IOSXE'
})

export const OS_TYPES_DISPLAY_MAP = Object.freeze({
  [OS_TYPES.IOS_XR]: 'IOS XR',
  [OS_TYPES.IOS_XE]: 'IOS XE'
})
