import Toast from '../Toast'
// import { users } from './testConstants'

export const USER_SEARCH_QUERY_CHANGED = 'USER_SEARCH_QUERY_CHANGED'
export const USER_SEARCH_USERS_REQUESTED = 'USER_SEARCH_USERS_REQUESTED'
export const USER_SEARCH_USERS_REQUEST_SUCCESS =
  'USER_SEARCH_USERS_REQUEST_SUCCESS'
export const USER_SEARCH_USERS_REQUEST_FAILURE =
  'USER_SEARCH_USERS_REQUEST_FAILURE'
export const USER_SEARCH_USER_SELECTED = 'USER_SEARCH_USER_SELECTED'
export const USER_SEARCH_USER_DESELECTED = 'USER_SEARCH_USER_DESELECTED'
export const RESET_USER_SEARCH = 'RESET_USER_SEARCH'

export const queryChanged = (query) => {
  return {
    type: USER_SEARCH_QUERY_CHANGED,
    query
  }
}

const usersRequested = (query) => {
  return {
    type: USER_SEARCH_USERS_REQUESTED,
    query
  }
}

const usersRequestSuccess = (query, users) => {
  return {
    type: USER_SEARCH_USERS_REQUEST_SUCCESS,
    query,
    users
  }
}

const usersRequestFailure = (query, error) => {
  return {
    type: USER_SEARCH_USERS_REQUEST_FAILURE,
    query,
    error
  }
}

export const userSelected = (user) => {
  return {
    type: USER_SEARCH_USER_SELECTED,
    user
  }
}

export const userDeselected = (user) => {
  return {
    type: USER_SEARCH_USER_DESELECTED,
    user
  }
}

export const resetUserSearch = () => {
  return {
    type: RESET_USER_SEARCH
  }
}

export const searchUsers = (query) => async (dispatch, getState) => {
  dispatch(usersRequested(query))

  // dispatch(usersRequestSuccess(query, users))

  try {
    const response = await fetch('/api/users/roles/user-lookup', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: query
      })
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(usersRequestSuccess(query, json.data))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(usersRequestFailure(query, e))
    Toast.show({
      message: `Failed to search for users - ${e}`,
      intent: 'danger'
    })
  }
}
