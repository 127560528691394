export const RELEASES_REQUESTED = 'RELEASES_REQUESTED'
export const RELEASES_REQUEST_SUCCESS = 'RELEASES_REQUEST_SUCCESS'
export const RELEASES_REQUEST_FAILURE = 'RELEASES_REQUEST_FAILURE'

const releasesRequested = () => {
  return {
    type: RELEASES_REQUESTED
  }
}

const releasesRequestSuccess = (releases) => {
  return {
    type: RELEASES_REQUEST_SUCCESS,
    releases
  }
}

const releasesRequestFailure = (error) => {
  return {
    type: RELEASES_REQUEST_FAILURE,
    error
  }
}

export const fetchReleases = () => async (dispatch, getState) => {
  dispatch(releasesRequested())
  try {
    const response = await fetch('/api/releases', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(releasesRequestSuccess(json.releases))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(releasesRequestFailure(e))
  }
}
