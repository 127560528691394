import {
  TOKENS_REQUESTED,
  TOKENS_REQUEST_FAILURE,
  TOKENS_REQUEST_SUCCESS,
  TOKEN_GENERATE_SUCCESS,
  TOKEN_REVOKE_SUCCESS
} from './actions'

const defaultState = {}

const tokenManager = (state = defaultState, action) => {
  switch (action.type) {
    case TOKENS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case TOKENS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        tokens: action.tokens
      }
    case TOKENS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case TOKEN_GENERATE_SUCCESS:
      return {
        ...state,
        tokens: [action.token, ...state.tokens]
      }
    case TOKEN_REVOKE_SUCCESS:
      return {
        ...state,
        tokens: state.tokens.filter((token) => token.id !== action.id)
      }
    default:
      return state
  }
}
export default tokenManager
