import './styles.scss'
import { Assertion, DateValue, Status } from '../../Renderers'
import Guard from '../../../Guard'
import { VoucherPermissions } from '../../../Guard/constants'
import ArchiveConfirmation from '../../ArchiveConfirmation'

import { useState } from 'react'
import {
  Button,
  Checkbox,
  Text,
  Menu,
  MenuItem,
  MenuDivider
} from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import { OS_TYPES_DISPLAY_MAP } from '../../../RequestForm/constants'

const ArchiveMenu = ({
  voucherID,
  reqID,
  archiveVoucher,
  archiveRequest,
  status,
  voucherOnly
}) => {
  const [showConfirm, setShowConfirm] = useState(voucherOnly ? 'voucher' : null)
  return (
    <Menu>
      {!voucherOnly && (
        <div
          className="animate__animated animate__zoomIn animate__fastest"
          onClick={showConfirm ? () => setShowConfirm(null) : undefined}
        >
          <MenuItem
            onClick={() => setShowConfirm('voucher')}
            disabled={status !== 'completed' || showConfirm}
            className="archive-voucher-button"
            icon="remove"
            text="Archive this Voucher"
            intent="danger"
            shouldDismissPopover={false}
          />
          <MenuItem
            onClick={() => setShowConfirm('request')}
            disabled={status !== 'completed' || showConfirm}
            className="archive-request-button"
            icon="cube-remove"
            text="Archive all Vouchers in this Request"
            intent="danger"
            shouldDismissPopover={false}
          />
        </div>
      )}
      {showConfirm && (
        <>
          {!voucherOnly && <MenuDivider />}
          <ArchiveConfirmation
            className="row-archive-confirmation-container animate__animated animate__zoomIn animate__fastest"
            onConfirm={() =>
              showConfirm === 'voucher'
                ? archiveVoucher(voucherID)
                : archiveRequest(reqID)
            }
            confirmText={showConfirm === 'voucher' ? 'Voucher' : 'Request'}
            request={showConfirm !== 'voucher'}
          />
        </>
      )}
    </Menu>
  )
}

const Row = ({
  reqID,
  voucherID,
  requestedAt,
  createdAt,
  expiresAt,
  assertion,
  status,
  serialNumber,
  pdcOrganization,
  osType,
  requestedBy,
  archiveRequest,
  archiveVoucher,
  downloadVoucher,
  isSelected,
  checkboxChanged,
  singleRequestMode,
  relatedVouchersMode
}) => {
  return (
    <tr
      className={classNames('row-container', {
        selected: isSelected,
        'single-request-mode': singleRequestMode,
        'related-vouchers-mode': relatedVouchersMode
      })}
    >
      {!relatedVouchersMode && (
        <td className="selection">
          <Checkbox
            disabled={status !== 'completed'}
            checked={isSelected}
            onChange={() => checkboxChanged(voucherID)}
          />
        </td>
      )}
      {!relatedVouchersMode && (
        <td className="serial-number">
          <Link to={`/voucher/${voucherID}`}>
            <Text ellipsize>{serialNumber}</Text>
          </Link>
        </td>
      )}
      {!singleRequestMode && (
        <>
          <td className="requested-by">{requestedBy}</td>
          <td className="requested-at date">
            <DateValue date={requestedAt} />
          </td>
        </>
      )}
      {singleRequestMode && (
        <td className="created-at date">
          <DateValue date={createdAt} />
        </td>
      )}
      <td
        className={classNames('expires-at date', {
          expired: Moment().isAfter(expiresAt)
        })}
      >
        <DateValue date={expiresAt} />
      </td>
      <td className="assertion">
        <Assertion assertion={assertion} />
      </td>
      <td className="status">
        <Status status={status} />
      </td>
      {!singleRequestMode && (
        <td className="request-id">
          <Link to={`/request/${reqID}`} title="View this request">
            <Text ellipsize>{reqID}</Text>
          </Link>
        </td>
      )}
      <td className="voucher-id" title="View this voucher">
        <Link to={`/voucher/${voucherID}`}>
          <Text ellipsize>{voucherID}</Text>
        </Link>
      </td>
      {!singleRequestMode && !relatedVouchersMode && (
        <td className="pdc-organization">{pdcOrganization ?? 'Unknown'}</td>
      )}
      {relatedVouchersMode && (
        <td className="os-type">
          {OS_TYPES_DISPLAY_MAP?.[osType] ?? 'Unknown'}
        </td>
      )}
      <td className="actions">
        <Guard
          permissions={[VoucherPermissions.DOWNLOAD_VOUCHER]}
          deniedProps={{ disabled: true }}
        >
          <Button
            title="Download"
            onClick={() => downloadVoucher(voucherID, serialNumber)}
            disabled={status !== 'completed'}
            className="download-button"
            icon="download"
            intent="primary"
            minimal
            small
          />
        </Guard>
        <Guard
          permissions={[VoucherPermissions.ARCHIVE_VOUCHER]}
          deniedProps={{ disabled: true }}
        >
          <Popover2
            popoverClassName="row-archive-confirm-popover"
            disabled={status !== 'completed'}
            rootBoundary="viewport"
            placement="bottom-end"
            content={
              <ArchiveMenu
                voucherID={voucherID}
                reqID={reqID}
                archiveVoucher={archiveVoucher}
                archiveRequest={archiveRequest}
                status={status}
                voucherOnly={singleRequestMode || relatedVouchersMode}
              />
            }
          >
            <Guard
              permissions={[VoucherPermissions.ARCHIVE_VOUCHER]}
              deniedProps={{ disabled: true }}
            >
              <Button
                title="Archive"
                disabled={status !== 'completed'}
                className="archive-button"
                icon="th-disconnect"
                intent="danger"
                minimal
                small
              />
            </Guard>
          </Popover2>
        </Guard>
      </td>
    </tr>
  )
}

export default Row
