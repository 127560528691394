import { humanizeDateTime } from '../../shared/utils'
import { AlertTypes } from './constants'
import './styles.scss'
import { Icon } from '@blueprintjs/core'

const AlertMessage = ({ alert }) => {
  switch (alert?.alert_type) {
    case AlertTypes.SCHEDULED_MAINTENANCE:
      return 'MASA Server may be temporarily unavailable during a scheduled maintenance window'
    case AlertTypes.SCHEDULED_UPDATE:
      return 'MASA Server may be temporarily unavailable due to planned updates'
    default:
      return alert?.message ?? 'MASA Server may be unavailable'
  }
}

const AlertsView = ({ alert }) => {
  return (
    <div className="alerts-container animate__animated animate__pulse animate__faster">
      <Icon icon="info-sign" size={18} />
      <AlertMessage alert={alert} />
      &nbsp;between&nbsp;
      {humanizeDateTime(alert.start_time, 'MMM D YYYY, h:mm A z')}
      &nbsp;and&nbsp;
      {humanizeDateTime(alert.end_time, 'MMM D YYYY, h:mm A z')}
    </div>
  )
}

export default AlertsView
