export const selectVoucher = (selectedVouchers, id) => {
  return { ...selectedVouchers, [id]: true }
}

export const deselectVoucher = (selectedVouchers, id) => {
  let selected = { ...selectedVouchers }
  delete selected[id]
  return selected
}

export const selectMultipleVouchers = (selectedVouchers, vouchers) => {
  return {
    ...selectedVouchers,
    ...vouchers?.reduce((all, voucher) => {
      if (voucher.status === 'completed')
        return { ...all, [voucher.voucher_id]: true }
      else return all
    }, {})
  }
}

export const deselectMultipleVouchers = (selectedVouchers, vouchers) => {
  let selected = { ...selectedVouchers }
  vouchers?.forEach((voucher) => {
    delete selected[voucher.voucher_id]
  })
  return selected
}

export const archiveVoucher = (vouchers, id) => {
  return vouchers?.map((voucher) => {
    if (voucher.voucher_id === id) return { ...voucher, status: 'archived' }
    else return voucher
  })
}

export const bulkArchiveVouchers = (vouchers, ids) => {
  if (!ids) return
  const toArchive = ids.reduce((all, id) => {
    return { ...all, [id]: true }
  }, {})
  return vouchers?.map((voucher) => {
    if (toArchive[voucher.voucher_id]) return { ...voucher, status: 'archived' }
    else return voucher
  })
}
