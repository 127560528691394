import BulkOperationsView from './BulkOperationsView'
import {
  bulkArchiveVouchers,
  bulkDownloadVouchers,
  resetVoucherSelection
} from '../../Voucher/actions'

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

const BulkOperations = ({ selectedVouchers }) => {
  const dispatch = useDispatch()

  const _resetVoucherSelection = useCallback(
    () => dispatch(resetVoucherSelection()),
    [dispatch]
  )

  const archiveVouchers = useCallback(() => {
    dispatch(bulkArchiveVouchers(Object.keys(selectedVouchers)))
  }, [dispatch, selectedVouchers])

  const downloadVouchers = useCallback(() => {
    dispatch(bulkDownloadVouchers(Object.keys(selectedVouchers)))
  }, [dispatch, selectedVouchers])

  return (
    <BulkOperationsView
      selectedVouchers={Object.keys(selectedVouchers)}
      resetVoucherSelection={_resetVoucherSelection}
      archiveVouchers={archiveVouchers}
      downloadVouchers={downloadVouchers}
    />
  )
}

export default BulkOperations
