import { combineReducers } from 'redux'
import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'

import app from './reducers'
import nav from './components/Nav/reducers'
import login from './components/Login/reducers'
import requestForm from './components/RequestForm/reducers'
import requests from './components/Requests/reducers'
import request from './components/Request/reducers'
import voucher from './components/Voucher/reducers'
import filters from './components/shared/Filters/reducers'
import tokenManager from './components/TokenManager/reducers'
import certificateManager from './components/CertificateManager/reducers'
import userInvitations from './components/UserInvitations/reducers'
import orgSwitcher from './components/OrgSwitcher/reducers'
import userSearch from './components/shared/UserSearch/reducers'
import accessControl from './components/AccessControl/reducers'
import releaseNotes from './components/ReleaseNotes/reducers'

const rootReducer = combineReducers({
  app,
  login,
  nav,
  requestForm,
  requests,
  request,
  voucher,
  filters,
  tokenManager,
  certificateManager,
  userInvitations,
  orgSwitcher,
  userSearch,
  accessControl,
  releaseNotes
})

// Modify Redux middleware based on environment settings
let middleware = [thunk]
if (process.env.NODE_ENV !== 'production') {
  const { logger } = require('redux-logger')
  middleware = [...middleware, logger]
}

export const configureStore = () => {
  return createStore(rootReducer, applyMiddleware(...middleware))
}
