import './styles.scss'
import { pluralize } from '../utils'
import Guard from '../../Guard'
import { VoucherPermissions } from '../../Guard/constants'

import { Button, Tag } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import ArchiveConfirmation from '../ArchiveConfirmation'

const BulkOperationsView = ({
  selectedVouchers,
  resetVoucherSelection,
  archiveVouchers,
  downloadVouchers
}) => {
  return selectedVouchers?.length > 0 ? (
    <div className="bulk-operations-container animate__animated animate__fadeInDown animate__fastest">
      <Button
        title="Clear Selection"
        onClick={resetVoucherSelection}
        icon="cross"
        minimal
        small
      >
        <Tag className="selection-count" round minimal>
          {selectedVouchers.length}
        </Tag>
      </Button>
      <Guard
        permissions={[VoucherPermissions.DOWNLOAD_VOUCHER]}
        deniedComponent={<></>}
      >
        <Button
          onClick={downloadVouchers}
          title="Download Selected Vouchers"
          icon="download"
          intent="primary"
          minimal
          small
        >
          <Tag className="selection-count" intent="primary" round minimal>
            {selectedVouchers.length}
          </Tag>
        </Button>
      </Guard>
      <Guard
        permissions={[VoucherPermissions.ARCHIVE_VOUCHER]}
        deniedComponent={<></>}
      >
        <Popover2
          popoverClassName="bulk-operations-archive-popover"
          placement="bottom-end"
          content={
            <ArchiveConfirmation
              onConfirm={archiveVouchers}
              confirmText={pluralize({
                word: 'voucher',
                count: selectedVouchers.length
              })}
              voucherCount={pluralize({
                word: 'selected voucher',
                count: selectedVouchers.length,
                ignoreQuantity: selectedVouchers.length === 1
              })}
            />
          }
        >
          <Button
            title="Archive Selected Vouchers"
            icon="th-disconnect"
            intent="danger"
            minimal
            small
          >
            <Tag className="selection-count" intent="danger" round minimal>
              {selectedVouchers.length}
            </Tag>
          </Button>
        </Popover2>
      </Guard>
    </div>
  ) : null
}

export default BulkOperationsView
