import LandingView from './LandingView'
import { useSelector } from 'react-redux'

const Landing = () => {
  const {
    releases,
    loading: releasesLoading,
    error: releasesLoadingError
  } = useSelector((state) => state.releaseNotes)

  return (
    <LandingView
      releasesLoading={releasesLoading}
      releasesLoadingError={releasesLoadingError}
      currentRelease={releases?.[0]}
    />
  )
}

export default Landing
