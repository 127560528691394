import {
  CLEAR_STATUS_FILTER,
  PDC_ORGANIZATIONS_REQUEST_SUCCESS,
  PDC_ORGANIZATION_FILTER_CHANGED,
  REQUESTS_DATE_RANGE_CHANGED,
  REQUESTS_SEARCH_CHANGED,
  REQUESTS_SORT_ORDER_CHANGED,
  REQUESTS_SORT_TYPE_CHANGED,
  RESET_FILTER_DATA,
  STATUS_FILTER_CHANGED
} from './actions'
import { SortTypes, StatusFilterTypes } from './constants'

const defaultState = {
  search: '',
  selectedDateRange: [],
  pdcOrganizations: [],
  selectedPDCOrganizations: [],
  selectedStatus: [StatusFilterTypes.IS_ACTIVE],
  sortOrder: true,
  sortType: [SortTypes.DATE_REQUESTED]
}

const filters = (state = defaultState, action) => {
  switch (action.type) {
    case REQUESTS_SEARCH_CHANGED:
      return {
        ...state,
        search: action.search
      }
    case REQUESTS_SORT_ORDER_CHANGED:
      return {
        ...state,
        sortOrder: action.sortOrder
      }
    case REQUESTS_SORT_TYPE_CHANGED:
      return {
        ...state,
        sortType: [action.sortType]
      }
    case REQUESTS_DATE_RANGE_CHANGED:
      return {
        ...state,
        selectedDateRange: action.selectedDateRange
      }
    case PDC_ORGANIZATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        pdcOrganizations: action.pdcOrganizations
      }
    case PDC_ORGANIZATION_FILTER_CHANGED:
      let selectedPDCOrganizations = []
      if (
        state.selectedPDCOrganizations.includes(action.selectedPDCOrganization)
      ) {
        selectedPDCOrganizations = state.selectedPDCOrganizations.filter(
          (pdcOrganization) =>
            pdcOrganization !== action.selectedPDCOrganization
        )
      } else {
        selectedPDCOrganizations = [
          ...state.selectedPDCOrganizations,
          action.selectedPDCOrganization
        ]
      }
      return {
        ...state,
        selectedPDCOrganizations
      }
    case STATUS_FILTER_CHANGED:
      return {
        ...state,
        selectedStatus:
          action.selectedStatus === state.selectedStatus?.[0]
            ? []
            : [action.selectedStatus]
      }
    case CLEAR_STATUS_FILTER:
      return {
        ...state,
        selectedStatus: []
      }
    case RESET_FILTER_DATA:
      return {
        ...defaultState,
        pdcOrganizations: state.pdcOrganizations
      }
    default:
      return state
  }
}
export default filters
