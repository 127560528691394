const Modules = Object.freeze({
  INVITATIONS: 'invitations',
  ACCESS_CONTROL: 'access_control',
  TOKEN_MANAGER: 'user',
  CERTIFICATE_MANAGER: 'certificate',
  VOUCHER: 'voucher',
  APP: 'app'
})

export const InvitationsPermissions = Object.freeze({
  CREATE_INVITE: { module: Modules.INVITATIONS, name: 'invite_people' },
  REVOKE_INVITE: { module: Modules.INVITATIONS, name: 'revoke_invite' }
})

export const AccessControlPermissions = Object.freeze({
  ASSIGN_ROLE: { module: Modules.ACCESS_CONTROL, name: 'assign_role' },
  REMOVE_ROLE: { module: Modules.ACCESS_CONTROL, name: 'remove_role' }
})

export const TokenManagerPermissions = Object.freeze({
  CREATE_TOKEN: { module: Modules.TOKEN_MANAGER, name: 'create_token' },
  REVOKE_TOKEN: { module: Modules.TOKEN_MANAGER, name: 'revoke_token' }
})

export const CertificateManagerPermissions = Object.freeze({
  ADD_CERTIFICATE: {
    module: Modules.CERTIFICATE_MANAGER,
    name: 'add_certificate'
  },
  REMOVE_CERTIFICATE: {
    module: Modules.CERTIFICATE_MANAGER,
    name: 'remove_certificate'
  }
})

export const VoucherPermissions = Object.freeze({
  REQUEST_VOUCHER: { module: Modules.VOUCHER, name: 'request_voucher' },
  ARCHIVE_VOUCHER: { module: Modules.VOUCHER, name: 'archive_voucher' },
  DOWNLOAD_VOUCHER: { module: Modules.VOUCHER, name: 'download_voucher' }
})

export const AppPermissions = Object.freeze({
  ADMIN: { module: Modules.APP, name: 'admin' },
  VIEW_USER: { module: Modules.APP, name: 'view_user' }
})
