import HomeView from './HomeView'
import { resetRequestForm } from '../RequestForm/actions'

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { setNavTitle } from '../Nav/actions'

const Home = () => {
  const dispatch = useDispatch()
  const { loading: requestLoading, certificate, serialNumbers } = useSelector(
    (state) => state.requestForm
  )
  const canClose = !requestLoading && !serialNumbers.length && !certificate

  const _resetRequestForm = useCallback(() => dispatch(resetRequestForm()), [
    dispatch
  ])

  useEffect(() => {
    dispatch(setNavTitle([{ text: 'MASA' }], 'Home'))
  }, [dispatch])

  return <HomeView canClose={canClose} resetRequestForm={_resetRequestForm} />
}

export default Home
