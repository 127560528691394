import { fetchRequests } from '../Requests/actions'
import { clearStatusFilter } from '../shared/Filters/actions'
import Toast from '../shared/Toast'
import { pluralize } from '../shared/utils'
import { SECURITY_PROFILE_MODES } from './SecurityProfileSelector/constants'
import { OS_TYPES } from './constants'

export const TOGGLE_USE_PRE_UPLOADED_CERTIFICATE =
  'TOGGLE_USE_PRE_UPLOADED_CERTIFICATE'
export const VOUCHER_REQUESTED = 'VOUCHER_REQUESTED'
export const VOUCHER_REQUEST_SUCCESS = 'VOUCHER_REQUEST_SUCCESS'
export const VOUCHER_REQUEST_FAILURE = 'VOUCHER_REQUEST_FAILURE'
export const CERTIFICATE_FIELD_CHANGED = 'CERTIFICATE_FIELD_CHANGED'
export const CERTIFICATE_FILE_LOADED = 'CERTIFICATE_FILE_LOADED'
export const SERIAL_NUMBERS_FIELD_CHANGED = 'SERIAL_NUMBERS_FIELD_CHANGED'
export const SERIAL_NUMBERS_FILE_LOADED = 'SERIAL_NUMBERS_FILE_LOADED'
export const PK_CERTIFICATE_FIELD_CHANGED = 'PK_CERTIFICATE_FIELD_CHANGED'
export const PK_CERTIFICATE_FILE_LOADED = 'PK_CERTIFICATE_FILE_LOADED'
export const OVERRIDE_FIELD_TOGGLED = 'OVERRIDE_FIELD_TOGGLED'
export const VOUCHER_EXPIRY_CHANGED = 'VOUCHER_EXPIRY_CHANGED'
export const SECURITY_PROFILE_FIELD_TOGGLED = 'SECURITY_PROFILE_FIELD_TOGGLED'
export const SECURITY_PROFILE_MODE_CHANGED = 'SECURITY_PROFILE_MODE_CHANGED'
export const SECURITY_PROFILE_SETTING_CHANGED =
  'SECURITY_PROFILE_SETTING_CHANGED'
export const OS_TYPE_FIELD_CHANGED = 'OS_TYPE_FIELD_CHANGED'
export const RESET_REQUEST_FORM = 'RESET_REQUEST_FORM'
export const REQUEST_FORM_CERTIFICATES_REQUESTED =
  'REQUEST_FORM_CERTIFICATES_REQUESTED'
export const REQUEST_FORM_CERTIFICATES_REQUEST_SUCCESS =
  'REQUEST_FORM_CERTIFICATES_REQUEST_SUCCESS'
export const REQUEST_FORM_CERTIFICATES_REQUEST_FAILURE =
  'REQUEST_FORM_CERTIFICATES_REQUEST_FAILURE'
export const REQUEST_FORM_SET_SELECTED_CERTIFICATE =
  'REQUEST_FORM_SET_SELECTED_CERTIFICATE'

export const setSelectedCertificate = (certificate) => {
  return {
    type: REQUEST_FORM_SET_SELECTED_CERTIFICATE,
    certificate
  }
}

const certificatesRequested = () => {
  return {
    type: REQUEST_FORM_CERTIFICATES_REQUESTED
  }
}

const certificatesRequestSuccess = (certificates) => {
  return {
    type: REQUEST_FORM_CERTIFICATES_REQUEST_SUCCESS,
    certificates
  }
}

const certificatesRequestFailure = (error) => {
  return {
    type: REQUEST_FORM_CERTIFICATES_REQUEST_FAILURE,
    error
  }
}

export const fetchCertificates = () => async (dispatch) => {
  dispatch(certificatesRequested())

  try {
    const response = await fetch('/api/certificates?expired=false', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()

    if (json.ok) {
      dispatch(certificatesRequestSuccess(json.certificates))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(certificatesRequestFailure(e))
    Toast.show({
      message: `Failed to load certificates - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'remove',
        onClick: () => dispatch(fetchCertificates())
      }
    })
  }
}

export const toggleUsePreUploadedCertificate = () => {
  return {
    type: TOGGLE_USE_PRE_UPLOADED_CERTIFICATE
  }
}

const voucherRequested = (certificate, serialNumbers, pkCertificate) => {
  return {
    type: VOUCHER_REQUESTED,
    certificate,
    serialNumbers,
    pkCertificate
  }
}

const voucherRequestSuccess = () => {
  return {
    type: VOUCHER_REQUEST_SUCCESS
  }
}

const voucherRequestFailure = (certificate, serialNumbers) => {
  return {
    type: VOUCHER_REQUEST_FAILURE,
    certificate,
    serialNumbers
  }
}

export const certificateFieldChanged = (certificate) => {
  return {
    type: CERTIFICATE_FIELD_CHANGED,
    certificate
  }
}

export const certificateFileLoaded = (certificateFile) => {
  return {
    type: CERTIFICATE_FILE_LOADED,
    certificateFile
  }
}

export const serialNumbersFieldChanged = (serialNumbers) => {
  return {
    type: SERIAL_NUMBERS_FIELD_CHANGED,
    serialNumbers
  }
}

export const serialNumbersFileLoaded = (serialNumbersFile) => {
  return {
    type: SERIAL_NUMBERS_FILE_LOADED,
    serialNumbersFile
  }
}

export const pkCertificateFieldChanged = (pkCertificate) => {
  return {
    type: PK_CERTIFICATE_FIELD_CHANGED,
    pkCertificate
  }
}

export const pkCertificateFileLoaded = (pkCertificateFile) => {
  return {
    type: PK_CERTIFICATE_FILE_LOADED,
    pkCertificateFile
  }
}

export const overrideFieldToggled = () => {
  return {
    type: OVERRIDE_FIELD_TOGGLED
  }
}

export const voucherExpiryChanged = (expiresAt) => {
  return {
    type: VOUCHER_EXPIRY_CHANGED,
    expiresAt
  }
}

export const securityProfileFieldToggled = () => {
  return {
    type: SECURITY_PROFILE_FIELD_TOGGLED
  }
}

export const securityProfileModeChanged = (securityProfileMode) => {
  return {
    type: SECURITY_PROFILE_MODE_CHANGED,
    securityProfileMode
  }
}

export const securityProfileSettingChanged = (profile, value) => {
  return {
    type: SECURITY_PROFILE_SETTING_CHANGED,
    profile,
    value
  }
}

export const osTypeFieldChanged = (osType) => {
  return {
    type: OS_TYPE_FIELD_CHANGED,
    osType
  }
}

export const resetRequestForm = () => {
  return {
    type: RESET_REQUEST_FORM
  }
}

export const requestVoucher = (callback) => async (dispatch, getState) => {
  const {
    certificate,
    pkCertificate,
    serialNumbers,
    override,
    osType,
    expiresAt,
    securityProfile,
    securityProfileMode,
    securityProfileSettings,
    usePreUploadedCertificate,
    selectedCertificate
  } = getState().requestForm

  if (
    (usePreUploadedCertificate ? !selectedCertificate : !certificate) ||
    !serialNumbers.length
  )
    return

  let body = {
    serial_numbers: serialNumbers,
    override: override ?? false,
    no_download: true,
    os_type: osType
  }

  if (usePreUploadedCertificate) {
    body.cert_id = selectedCertificate.cert_id
  } else {
    body.certificate = certificate
  }

  if (expiresAt)
    body = {
      ...body,
      expires_at: expiresAt.toISOString()
    }

  if (securityProfile) {
    body = {
      ...body,
      security_profile: true,
      security_profile_mode: securityProfileMode
    }
    if (securityProfileMode === SECURITY_PROFILE_MODES.CUSTOM) {
      body = {
        ...body,
        security_profile_settings: securityProfileSettings
      }
    }
  }

  if (pkCertificate) {
    body = {
      ...body,
      pk_certificate: pkCertificate,
      os_type: OS_TYPES.IOS_XR
    }
    dispatch(voucherRequested(certificate, serialNumbers, pkCertificate))
  } else {
    dispatch(voucherRequested(certificate, serialNumbers))
  }

  try {
    const response = await fetch('/api/request/ov', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const json = await response.json()
    if (json.ok) {
      if (callback) callback()
      dispatch(resetRequestForm())
      if (json.vouchers?.length) {
        dispatch(voucherRequestSuccess())
        Toast.show({
          message: `Successfully created ${pluralize({
            word: 'voucher',
            count: json.vouchers.length
          })}`,
          intent: 'success'
        })
      }
      if (json.errors?.length) {
        dispatch(clearStatusFilter())
        json.errors.forEach((error) => {
          Toast.show({
            message: `${error.serial_number} - ${error.error}`,
            intent: 'danger'
          })
        })
      }
      dispatch(fetchRequests())
    } else {
      let error
      if (json.error.msg?.info) error = json.error.msg.info
      else error = json.error.msg
      throw new Error(error ?? 'Something went wrong', {
        cause: json.error.msg
      })
    }
  } catch (e) {
    dispatch(voucherRequestFailure(certificate, serialNumbers))
    if (e?.cause?.unknown) {
      Toast.show({
        message: `Unknown Serial Numbers - ${e?.cause?.unknown}`,
        intent: 'warning'
      })
    }
    if (e?.cause?.unauthorized) {
      Toast.show({
        message: `Unauthorized Serial Numbers - ${e?.cause?.unauthorized}`,
        intent: 'warning'
      })
    }
    if (e?.cause?.existing) {
      e?.cause?.existing.forEach((existing) => {
        Toast.show({
          message: `${existing.serial_number} - ${existing.error} ${
            existing.voucher_id ? `[Voucher ID - ${existing.voucher_id}]` : ''
          }`,
          intent: 'warning'
        })
      })
    }
    Toast.show({
      message: `Failed to request ${pluralize({
        word: 'voucher',
        count: serialNumbers.length,
        ignoreQuantity: true
      })} - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(requestVoucher(callback))
      }
    })
  }
}
