import './styles.scss'

import { useEffect, useState } from 'react'
import { Button } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { DateRangePicker as BlueprintDateRangePicker } from '@blueprintjs/datetime'
import Moment from 'moment'

export const RenderDate = ({ date = [] }) => {
  const [startDate, endDate] = date

  if (startDate && endDate) {
    return `${Moment(startDate).format('MMM D, YYYY')} - ${Moment(
      endDate
    ).format('MMM D, YYYY')}`
  } else if (startDate) {
    return `${Moment(startDate).format('MMM D, YYYY')}`
  }
  return 'Select A Date Range'
}

const DateRangePicker = ({ onChange, value, disabled, ...rest }) => {
  const [dates, setDates] = useState(value)

  useEffect(() => {
    setDates(value)
  }, [value])

  return (
    <Popover2
      popoverClassName="shared-daterange-picker-popover"
      placement="bottom"
      onClose={(e) => {
        if (!e?.target?.innerText?.includes('Past')) {
          if (dates?.[0]) onChange(dates)
          else onChange([])
        }
      }}
      disabled={disabled}
      content={
        <BlueprintDateRangePicker
          highlightCurrentDay
          onChange={setDates}
          onShortcutChange={(shortcut) => {
            setDates(shortcut.dateRange)
            onChange(shortcut.dateRange)
          }}
          value={dates}
          {...rest}
        />
      }
    >
      <Button
        className="shared-daterange-picker-button"
        icon="calendar"
        disabled={disabled}
        intent={dates?.length ? 'primary' : undefined}
      >
        <RenderDate date={dates} />
      </Button>
    </Popover2>
  )
}

export default DateRangePicker
