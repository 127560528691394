import HomeActionsView from './HomeActionsView'
import './styles.scss'
import { resetFilterData } from '../../shared/Filters/actions'

import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { StatusFilterTypes } from '../../shared/Filters/constants'

const HomeActions = ({ openRequestForm }) => {
  const { selectedVouchers } = useSelector((state) => state.requests)
  const {
    search,
    selectedStatus,
    selectedPDCOrganizations,
    selectedDateRange
  } = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  const resetFilters = useCallback(
    () => dispatch(resetFilterData()),
    [dispatch]
  )

  const showReset = useCallback(() => {
    return (
      search ||
      !selectedStatus?.includes(StatusFilterTypes.IS_ACTIVE) ||
      selectedPDCOrganizations?.length > 0 ||
      selectedDateRange?.length > 0
    )
  }, [search, selectedStatus, selectedPDCOrganizations, selectedDateRange])

  return (
    <HomeActionsView
      openRequestForm={openRequestForm}
      selectedVouchers={selectedVouchers}
      resetFilters={resetFilters}
      showReset={showReset()}
    />
  )
}

export default HomeActions
