import Toast from '../shared/Toast'

export const USER_INVITATIONS_REQUESTED = 'USER_INVITATIONS_REQUESTED'
export const USER_INVITATIONS_REQUEST_SUCCESS =
  'USER_INVITATIONS_REQUEST_SUCCESS'
export const USER_INVITATIONS_REQUEST_FAILURE =
  'USER_INVITATIONS_REQUEST_FAILURE'
export const USER_INVITATIONS_DISPATCHED = 'USER_INVITATIONS_DISPATCHED'
export const USER_INVITATIONS_DISPATCH_SUCCESS =
  'USER_INVITATIONS_DISPATCH_SUCCESS'
export const USER_INVITATIONS_DISPATCH_FAILURE =
  'USER_INVITATIONS_DISPATCH_FAILURE'
export const USER_INVITATION_REVOKE_REQUESTED =
  'USER_INVITATION_REVOKE_REQUESTED'
export const USER_INVITATION_REVOKE_SUCCESS = 'USER_INVITATION_REVOKE_SUCCESS'
export const USER_INVITATION_REVOKE_FAILURE = 'USER_INVITATION_REVOKE_FAILURE'

const userInvitationsRequested = () => {
  return {
    type: USER_INVITATIONS_REQUESTED
  }
}

const userInvitationsRequestSuccess = (invitations) => {
  return {
    type: USER_INVITATIONS_REQUEST_SUCCESS,
    invitations
  }
}

const userInvitationsRequestFailure = (error) => {
  return {
    type: USER_INVITATIONS_REQUEST_FAILURE,
    error
  }
}

const userInvitationsDispatched = (users, message) => {
  return {
    type: USER_INVITATIONS_DISPATCHED,
    users,
    message
  }
}

const userInvitationsDispatchSuccess = (users) => {
  return {
    type: USER_INVITATIONS_DISPATCH_SUCCESS,
    users
  }
}

const userInvitationsDispatchFailure = (users, error) => {
  return {
    type: USER_INVITATIONS_DISPATCH_FAILURE,
    users,
    error
  }
}

const userInvitationRevokeRequested = (user) => {
  return {
    type: USER_INVITATION_REVOKE_REQUESTED,
    user
  }
}

const userInvitationRevokeSuccess = (user) => {
  return {
    type: USER_INVITATION_REVOKE_SUCCESS,
    user
  }
}

const userInvitationRevokeFailure = (user, error) => {
  return {
    type: USER_INVITATION_REVOKE_FAILURE,
    user,
    error
  }
}

export const fetchUserInvitations = () => async (dispatch) => {
  dispatch(userInvitationsRequested())

  try {
    const response = await fetch('/api/users/invitations', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(userInvitationsRequestSuccess(json.data))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(userInvitationsRequestFailure(e))
    Toast.show({
      message: `Failed to load invitations - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(fetchUserInvitations())
      }
    })
  }
}

export const dispatchInvitations =
  (users, message, successCallback) => async (dispatch) => {
    dispatch(userInvitationsDispatched(users, message))
    try {
      const response = await fetch('/api/users/invite', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          users,
          message
        })
      })
      const json = await response.json()
      if (json.ok) {
        dispatch(userInvitationsDispatchSuccess(users))
        dispatch(fetchUserInvitations())
        successCallback()
      } else {
        let error
        if (json.error.msg?.info) error = json.error.msg.info
        else error = json.error.msg
        throw new Error(error ?? 'Something went wrong', {
          cause: json.error.msg
        })
      }
    } catch (e) {
      dispatch(userInvitationsDispatchFailure(e))
      if (e?.cause?.invalid) {
        Toast.show({
          message: `Invalid - ${e?.cause?.invalid}`,
          intent: 'warning'
        })
      }
      Toast.show({
        message: `Failed to dispatch invitations - ${e}`,
        intent: 'danger',
        action: {
          text: 'Try again',
          icon: 'repeat',
          onClick: () => dispatch(dispatchInvitations(users, message))
        }
      })
    }
  }

export const revokeInvitation = (user, status) => async (dispatch) => {
  dispatch(userInvitationRevokeRequested(user))
  try {
    const response = await fetch('/api/users/revoke', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        users: [user]
      })
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(userInvitationRevokeSuccess(user))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(userInvitationRevokeFailure(user, e))
    Toast.show({
      message: `Failed to ${status ? 'remove' : 'revoke'} invitation - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(revokeInvitation(user, status))
      }
    })
  }
}
