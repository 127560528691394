import { Button } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

import './styles.scss'

const UnauthenticatedHeaderView = ({ title }) => {
  return (
    <div className="unauthenticated-header-container animate__animated animate__fadeInDown animate__fastest">
      <div className="unauthenticated-header-actions">
        <Link to="/" className="unauthenticated-header-actions-back-button">
          <Button icon="arrow-left" minimal />
        </Link>
      </div>
      <div className="unauthenticated-header-title">
        <Link to="/" className="unauthenticated-header-title-logo">
          <div className="masa-logo" />
        </Link>
        {title && (
          <>
            <div className="unauthenticated-header-title-divider" />
            <div className="unauthenticated-header-title-name">{title}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default UnauthenticatedHeaderView
