import FiltersView from './FiltersView'
import {
  dateRangeChanged,
  fetchPDCOrganizations,
  pdcOrganizationFilterChanged,
  resetFilterData,
  searchChanged,
  sortOrderChanged,
  sortTypeChanged,
  statusFilterChanged
} from './actions'

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import _ from 'lodash'

const Filters = ({ items, loading, compact }) => {
  const {
    search,
    sortOrder,
    sortType,
    pdcOrganizations,
    selectedPDCOrganizations,
    selectedStatus,
    selectedDateRange
  } = useSelector((state) => state.filters)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!compact) dispatch(fetchPDCOrganizations())
    return () => {
      dispatch(resetFilterData())
    }
  }, [dispatch, compact])

  const _searchChanged = _.debounce(
    useCallback(
      (value) => {
        dispatch(searchChanged(value))
      },
      [dispatch]
    ),
    500
  )

  const _sortOrderChanged = useCallback(
    (order) => dispatch(sortOrderChanged(order)),
    [dispatch]
  )

  const _sortTypeChanged = useCallback(
    (type) => {
      if (type !== sortType?.[0]) dispatch(sortTypeChanged(type))
    },
    [dispatch, sortType]
  )

  const _statusFilterChanged = useCallback(
    (_, selectedStatus) => {
      dispatch(statusFilterChanged(selectedStatus))
    },
    [dispatch]
  )

  const _pdcOrganizationFilterChanged = useCallback(
    (_, selectedPDCOrganization) => {
      dispatch(pdcOrganizationFilterChanged(selectedPDCOrganization))
    },
    [dispatch]
  )

  const _dateRangeChanged = useCallback(
    (dateRange) => {
      dispatch(dateRangeChanged(dateRange))
    },
    [dispatch]
  )

  return (
    <FiltersView
      loading={loading}
      items={items}
      searchChanged={_searchChanged}
      search={search}
      sortOrder={sortOrder}
      sortType={sortType}
      sortOrderChanged={_sortOrderChanged}
      sortTypeChanged={_sortTypeChanged}
      pdcOrganizations={pdcOrganizations}
      selectedPDCOrganizations={selectedPDCOrganizations}
      selectedStatus={selectedStatus}
      statusFilterChanged={_statusFilterChanged}
      pdcOrganizationFilterChanged={_pdcOrganizationFilterChanged}
      selectedDateRange={selectedDateRange}
      dateRangeChanged={_dateRangeChanged}
      compact={compact}
    />
  )
}

export default Filters
