import { useSelector } from 'react-redux'
import SecurityProfileSelectorView from './SecurityProfileSelectorView'
import {
  securityProfileModeChanged,
  securityProfileSettingChanged
} from '../actions'
import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { SECURITY_PROFILE_MODES } from './constants'

const SecurityProfileSelector = () => {
  const { securityProfile, securityProfileMode, securityProfileSettings } =
    useSelector((state) => state.requestForm)

  const dispatch = useDispatch()

  const _securityProfileModeChanged = useCallback(
    (securityProfileMode) => {
      dispatch(securityProfileModeChanged(securityProfileMode))
    },
    [dispatch]
  )

  const _securityProfileSettingChanged = useCallback(
    (profile, value) => {
      dispatch(securityProfileSettingChanged(profile, value))
    },
    [dispatch]
  )

  useEffect(() => {
    if (securityProfileMode === SECURITY_PROFILE_MODES.CUSTOM) {
      const element = document.querySelector(
        '.custom-security-profile-mode-settings'
      )
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [securityProfileMode])

  useEffect(() => {
    if (securityProfile) {
      const element = document.querySelector('.security-profile-presets')
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [securityProfile])

  return (
    <SecurityProfileSelectorView
      securityProfileMode={securityProfileMode}
      securityProfileModeChanged={_securityProfileModeChanged}
      securityProfileSettings={securityProfileSettings}
      securityProfileSettingChanged={_securityProfileSettingChanged}
    />
  )
}

export default SecurityProfileSelector
