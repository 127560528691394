import './styles.scss'
import Nav from '../Nav'
import Menu from '../Menu'
import Home from '../Home'
import RouterError from '../shared/RouterError'

import { lazy, Suspense } from 'react'
import { Spinner } from '@blueprintjs/core'
import { Route, Routes, Navigate } from 'react-router-dom'

// Lazy Loaded components
const Documentation = lazy(() => import('../Documentation'))
const ReleaseNotes = lazy(() => import('../ReleaseNotes'))
const Settings = lazy(() => import('../Settings'))
const Request = lazy(() => import('../Request'))
const Voucher = lazy(() => import('../Voucher'))

const AuthGuard = () => {
  return (
    <>
      <Nav />
      <Menu />
      <div className="app-content">
        <Suspense fallback={<Spinner className="spinner-position" />}>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/docs" element={<Documentation />} />
            <Route path="/releases" element={<ReleaseNotes />} />
            <Route path="/request/:id" element={<Request />} />
            <Route path="/voucher/:id" element={<Voucher />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<RouterError />} />
          </Routes>
        </Suspense>
      </div>
    </>
  )
}

export default AuthGuard
