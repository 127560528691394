import Toast from '../shared/Toast'

export const RESET_NEW_CERTIFICATE = 'RESET_NEW_CERTIFICATE'
export const SET_NEW_CERTIFICATE_INPUT = 'SET_NEW_CERTIFICATE_INPUT'
export const CERTIFICATES_REQUESTED = 'CERTIFICATES_REQUESTED'
export const CERTIFICATES_REQUEST_SUCCESS = 'CERTIFICATES_REQUEST_SUCCESS'
export const CERTIFICATES_REQUEST_FAILURE = 'CERTIFICATES_REQUEST_FAILURE'
export const CERTIFICATE_ADD_REQUESTED = 'CERTIFICATE_ADD_REQUESTED'
export const CERTIFICATE_ADD_SUCCESS = 'CERTIFICATE_ADD_SUCCESS'
export const CERTIFICATE_ADD_FAILURE = 'CERTIFICATE_ADD_FAILURE'
export const CERTIFICATE_REMOVE_REQUESTED = 'CERTIFICATE_REMOVE_REQUESTED'
export const CERTIFICATE_REMOVE_SUCCESS = 'CERTIFICATE_REMOVE_SUCCESS'
export const CERTIFICATE_REMOVE_FAILURE = 'CERTIFICATE_REMOVE_FAILURE'
export const CERTIFICATE_NEW_FILE_LOADED = 'CERTIFICATE_NEW_FILE_LOADED'

export const certificateFileLoaded = (certificateFile) => {
  return {
    type: CERTIFICATE_NEW_FILE_LOADED,
    certificateFile
  }
}

const certificatesRequested = () => {
  return {
    type: CERTIFICATES_REQUESTED
  }
}

const certificatesRequestSuccess = (certificates) => {
  return {
    type: CERTIFICATES_REQUEST_SUCCESS,
    certificates
  }
}

const certificatesRequestFailure = (error) => {
  return {
    type: CERTIFICATES_REQUEST_FAILURE,
    error
  }
}

const certificateAddRequested = (certificate) => {
  return {
    type: CERTIFICATE_ADD_REQUESTED,
    certificate
  }
}

const certificateAddSuccess = (certificate) => {
  return {
    type: CERTIFICATE_ADD_SUCCESS,
    certificate
  }
}

const certificateAddFailure = (error) => {
  return {
    type: CERTIFICATE_ADD_FAILURE,
    error
  }
}

const certificateRemoveRequested = () => {
  return {
    type: CERTIFICATE_REMOVE_REQUESTED
  }
}

const certificateRemoveSuccess = (id) => {
  return {
    type: CERTIFICATE_REMOVE_SUCCESS,
    id
  }
}

const certificateRemoveFailure = (id, error) => {
  return {
    type: CERTIFICATE_REMOVE_FAILURE,
    id,
    error
  }
}

export const resetNewCertificate = () => {
  return {
    type: RESET_NEW_CERTIFICATE
  }
}

export const setNewCertificateInput = (value, field) => {
  return {
    type: SET_NEW_CERTIFICATE_INPUT,
    field: field,
    value: value
  }
}

export const fetchCertificates = () => async (dispatch) => {
  dispatch(certificatesRequested())

  try {
    const response = await fetch('/api/certificates', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()

    if (json.ok) {
      dispatch(certificatesRequestSuccess(json.certificates))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(certificatesRequestFailure(e))
    Toast.show({
      message: `Failed to load certificates - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(fetchCertificates())
      }
    })
  }
}

export const addCertificate = (_, successCallback) => async (dispatch) => {
  let finalNewCertificateData = {
    certificate: _.content,
    revocation_checks: _.revocation_checks
  }
  if (_.expires_at !== null) {
    finalNewCertificateData.expires_at = _.expires_at
  }
  if (_.name !== null && _.name.length) {
    finalNewCertificateData.name = _.name
  }

  dispatch(certificateAddRequested(finalNewCertificateData))
  try {
    const response = await fetch('/api/certificate', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(finalNewCertificateData)
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(certificateAddSuccess(json.certificate))
      successCallback()
      Toast.show({
        message: (
          <>
            Added New Certificate - {json.certificate.pdc_organization}
            {json.certificate.name ? ' - ' + json.certificate.name : ''}
          </>
        ),
        icon: 'label',
        intent: 'success'
      })
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(certificateAddFailure(e))
    Toast.show({
      message: `Failed to add certificate - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(addCertificate(_))
      }
    })
  }
}

export const removeCertificate = (certificate) => async (dispatch) => {
  dispatch(certificateRemoveRequested(certificate.cert_id))
  try {
    const response = await fetch(`/api/certificate/${certificate.cert_id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(certificateRemoveSuccess(certificate.cert_id))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(certificateRemoveFailure(certificate.cert_id, e))
    Toast.show({
      message: `Failed to remove certificate - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(removeCertificate(certificate))
      }
    })
  }
}
