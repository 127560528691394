import { errorMap } from './constants'

import SSOFailureView from './SSOFailureView'

import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

const SSOFailure = () => {
  const location = useLocation()

  const getErrorMessage = useCallback(() => {
    const search = new URLSearchParams(location.search)
    const errorCode = search.get('auth_code')
    return errorMap[errorCode]
  }, [location])

  return <SSOFailureView message={getErrorMessage()} />
}

export default SSOFailure
