import Moment from 'moment-timezone'

export const pluralize = ({
  word,
  count,
  pluralAdditive = 's',
  useNoForZero = false,
  ignoreQuantity = false
}) => {
  if (ignoreQuantity) return `${word}${count === 1 ? '' : pluralAdditive}`
  return `${count === 0 && useNoForZero ? 'No' : count} ${word}${
    count === 1 ? '' : pluralAdditive
  }`
}

export const humanizeDateTime = (
  dateTimeString,
  format = 'MMM D YYYY, h:mm A'
) => {
  return Moment.utc(dateTimeString).tz(Moment.tz.guess()).format(format)
}

export const humanizeDuration = (durationInMinutes, limit = false) => {
  if (durationInMinutes <= 0) {
    return '0m'
  }

  let willLimit = false
  let timeStr = ''
  if (Math.floor(durationInMinutes / 43800)) {
    timeStr += `${Math.floor(durationInMinutes / 43800)}mo`
    durationInMinutes = durationInMinutes % 43800
    willLimit = true && limit
  }
  if (Math.floor(durationInMinutes / 10080)) {
    if (timeStr) timeStr += ' '
    timeStr += `${Math.floor(durationInMinutes / 10080)}w`
    durationInMinutes = durationInMinutes % 10080
    willLimit = true && limit
  }
  if (Math.floor(durationInMinutes / 1440)) {
    if (timeStr) timeStr += ' '
    timeStr += `${Math.floor(durationInMinutes / 1440)}d`
    durationInMinutes = durationInMinutes % 1440
  }
  if (Math.floor(durationInMinutes / 60)) {
    if (timeStr) timeStr += ' '
    timeStr += `${Math.floor(durationInMinutes / 60)}h`
    durationInMinutes = durationInMinutes % 60
  }
  if (durationInMinutes && !limit && !willLimit) {
    const minutes = Math.floor(durationInMinutes)
    const seconds = Math.floor((durationInMinutes % 1) * 60)

    if (timeStr) {
      if (minutes !== 0) timeStr += ` ${minutes}m`
    } else {
      if (!Number.isInteger(durationInMinutes)) {
        if (minutes) timeStr += `${minutes}m`
        if (minutes && seconds) timeStr += ' '
        if (seconds) timeStr += `${seconds}s`
      } else timeStr += `${minutes}m`
    }
  }
  return timeStr
}
