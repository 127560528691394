import Toast from '../shared/Toast'
import { pluralize } from '../shared/utils'

export const USER_PERMISSIONS_REQUESTED = 'USER_PERMISSIONS_REQUESTED'
export const USER_PERMISSIONS_REQUEST_SUCCESS =
  'USER_PERMISSIONS_REQUEST_SUCCESS'
export const USER_PERMISSIONS_REQUEST_FAILURE =
  'USER_PERMISSIONS_REQUEST_FAILURE'
export const USER_PERMISSIONS_ROLES_REQUESTED =
  'USER_PERMISSIONS_ROLES_REQUESTED'
export const USER_PERMISSIONS_ROLES_REQUEST_SUCCESS =
  'USER_PERMISSIONS_ROLES_REQUEST_SUCCESS'
export const USER_PERMISSIONS_ROLES_REQUEST_FAILURE =
  'USER_PERMISSIONS_ROLES_REQUEST_FAILURE'
export const USER_PERMISSIONS_USERS_ADDED = 'USER_PERMISSIONS_USERS_ADDED'
export const USER_PERMISSIONS_ALL_ROLES_REMOVED =
  'USER_PERMISSIONS_ALL_ROLES_REMOVED'
export const USER_PERMISSIONS_ROLE_REMOVED = 'USER_PERMISSIONS_ROLE_REMOVED'
export const USER_PERMISSIONS_ROLE_CHANGED = 'USER_PERMISSIONS_ROLE_CHANGED'

const permissionsRequested = () => {
  return {
    type: USER_PERMISSIONS_REQUESTED
  }
}

const permissionsRequestSuccess = (users) => {
  return {
    type: USER_PERMISSIONS_REQUEST_SUCCESS,
    users
  }
}

const permissionsRequestFailure = (error) => {
  return {
    type: USER_PERMISSIONS_REQUEST_FAILURE,
    error
  }
}

const rolesRequested = () => {
  return {
    type: USER_PERMISSIONS_ROLES_REQUESTED
  }
}

const rolesRequestSuccess = (roles) => {
  return {
    type: USER_PERMISSIONS_ROLES_REQUEST_SUCCESS,
    roles
  }
}

const rolesRequestFailure = (error) => {
  return {
    type: USER_PERMISSIONS_ROLES_REQUEST_FAILURE,
    error
  }
}

export const usersAdded = (users, role, isPartial) => {
  return {
    type: USER_PERMISSIONS_USERS_ADDED,
    users,
    role,
    isPartial
  }
}

export const allRolesRemoved = (user, roles) => {
  return {
    type: USER_PERMISSIONS_ALL_ROLES_REMOVED,
    user,
    roles
  }
}

export const roleRemoved = (user, role) => {
  return {
    type: USER_PERMISSIONS_ROLE_REMOVED,
    user,
    role
  }
}

export const roleChanged = (role) => {
  return {
    type: USER_PERMISSIONS_ROLE_CHANGED,
    role
  }
}

export const fetchPermissions = () => async (dispatch) => {
  dispatch(permissionsRequested())

  try {
    const response = await fetch('/api/users/roles/user-lookup', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(permissionsRequestSuccess(json.data))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(permissionsRequestFailure(e))
    Toast.show({
      message: `Failed to fetch permissions - ${e}`,
      intent: 'danger'
    })
  }
}

export const fetchRoles = () => async (dispatch) => {
  dispatch(rolesRequested())

  try {
    const response = await fetch('/api/users/roles', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(rolesRequestSuccess(json.data))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(rolesRequestFailure(e))
    Toast.show({
      message: `Failed to fetch roles - ${e}`,
      intent: 'danger'
    })
  }
}

export const addUsers = (users, role, successCallback) => async (dispatch) => {
  try {
    const response = await fetch('/api/users/roles/change', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        users: users.map((user) => user.user_id),
        roles: [role.id]
      })
    })
    const json = await response.json()
    if (json.ok) {
      const addedUsers = users.filter((user) =>
        json.data.users.includes(user.user_id)
      )
      const isPartial = users.length !== addedUsers.length
      if (isPartial) {
        const remainingUsers = users.filter(
          (user) => !json.data.users.includes(user.user_id)
        )
        Toast.show({
          message: `Failed to assign roles to ${remainingUsers.length} of ${
            addedUsers.length + remainingUsers.length
          } users`,
          intent: 'danger',
          action: {
            text: 'Try again',
            icon: 'repeat',
            onClick: () => dispatch(addUsers(remainingUsers, role))
          }
        })
      } else {
        Toast.show({
          message: `${role.role} role assigned to ${pluralize({
            word: 'user',
            count: users.length
          })} successfully`,
          intent: 'success'
        })
      }
      dispatch(usersAdded(addedUsers, role, isPartial))
      successCallback()
    } else throw new Error(json.error.msg)
  } catch (e) {
    Toast.show({
      message: `Failed to assign roles - ${e}`,
      intent: 'danger'
    })
  }
}

export const removeUser = (user, roles, allRoles) => async (dispatch) => {
  try {
    const response = await fetch('/api/users/roles/change', {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        users: [user.user_id],
        roles: roles.map((role) => role.id)
      })
    })
    const json = await response.json()
    if (json.ok) {
      if (allRoles) dispatch(allRolesRemoved(user, roles))
      else dispatch(roleRemoved(user, roles[0]))
    } else throw new Error(json.error.msg)
  } catch (e) {
    Toast.show({
      message: `Failed to remove ${
        allRoles ? user.email : `${roles[0].role} role for ${user.email}`
      } - ${e}`,
      intent: 'danger'
    })
  }
}
