import MenuView from './MenuView'
import { logout } from '../Login/actions'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const Menu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    releases,
    loading: releasesLoading,
    error: releasesLoadingError
  } = useSelector((state) => state.releaseNotes)

  const logoutClicked = () => {
    dispatch(logout(navigate))
  }

  return (
    <MenuView
      logoutClicked={logoutClicked}
      releasesLoading={releasesLoading}
      releasesLoadingError={releasesLoadingError}
      currentRelease={releases?.[0]}
    />
  )
}

export default Menu
