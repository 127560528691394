import { SECURITY_PROFILE_MODES } from './SecurityProfileSelector/constants'
import {
  REQUEST_FORM_SET_SELECTED_CERTIFICATE,
  REQUEST_FORM_CERTIFICATES_REQUESTED,
  REQUEST_FORM_CERTIFICATES_REQUEST_SUCCESS,
  REQUEST_FORM_CERTIFICATES_REQUEST_FAILURE,
  TOGGLE_USE_PRE_UPLOADED_CERTIFICATE,
  CERTIFICATE_FIELD_CHANGED,
  CERTIFICATE_FILE_LOADED,
  PK_CERTIFICATE_FIELD_CHANGED,
  PK_CERTIFICATE_FILE_LOADED,
  OVERRIDE_FIELD_TOGGLED,
  RESET_REQUEST_FORM,
  SERIAL_NUMBERS_FIELD_CHANGED,
  SERIAL_NUMBERS_FILE_LOADED,
  VOUCHER_REQUESTED,
  VOUCHER_REQUEST_FAILURE,
  VOUCHER_REQUEST_SUCCESS,
  OS_TYPE_FIELD_CHANGED,
  SECURITY_PROFILE_FIELD_TOGGLED,
  SECURITY_PROFILE_SETTING_CHANGED,
  SECURITY_PROFILE_MODE_CHANGED,
  VOUCHER_EXPIRY_CHANGED
} from './actions'
import { OS_TYPES } from './constants'

const validateSerialNumbers = (serialNumbers) => {
  let valid = []
  let invalid = []
  serialNumbers.forEach((serial) => {
    const trimmedSerial = serial.trim()
    if (trimmedSerial.length > 0) {
      if (!/\s/g.test(trimmedSerial)) valid.push(trimmedSerial)
      else invalid.push(trimmedSerial)
    }
  })
  const unique = [...new Set(valid)]
  const duplicate = valid.filter((e, i, a) => a.indexOf(e) !== i)

  return { unique, invalid, duplicate }
}

const defaultState = {
  serialNumbers: [],
  osType: OS_TYPES.IOS_XR,
  usePreUploadedCertificate: false,
  preUploadedCertificates: [],
  selectedCertificate: null
}

const requestForm = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_FORM_SET_SELECTED_CERTIFICATE:
      return {
        ...state,
        selectedCertificate: action.certificate
      }
    case REQUEST_FORM_CERTIFICATES_REQUESTED:
      return {
        ...state,
        certificatesLoading: true,
        certificatesLoadingerror: null
      }
    case REQUEST_FORM_CERTIFICATES_REQUEST_SUCCESS:
      return {
        ...state,
        certificatesLoading: false,
        preUploadedCertificates: action.certificates
      }
    case REQUEST_FORM_CERTIFICATES_REQUEST_FAILURE:
      return {
        ...state,
        certificatesLoading: false,
        certificatesLoadingError: action.error
      }
    case TOGGLE_USE_PRE_UPLOADED_CERTIFICATE:
      return {
        ...state,
        usePreUploadedCertificate: !state.usePreUploadedCertificate
      }
    case CERTIFICATE_FIELD_CHANGED:
      return {
        ...state,
        certificate: action.certificate,
        certificateFile: undefined
      }
    case CERTIFICATE_FILE_LOADED:
      return {
        ...state,
        certificateFile: action.certificateFile
      }
    case SERIAL_NUMBERS_FIELD_CHANGED:
      const { unique, invalid, duplicate } = validateSerialNumbers(
        action.serialNumbers
      )
      return {
        ...state,
        serialNumbers: unique,
        invalid: invalid,
        duplicate: duplicate,
        serialNumbersFile: undefined
      }
    case SERIAL_NUMBERS_FILE_LOADED:
      return {
        ...state,
        serialNumbersFile: action.serialNumbersFile
      }
    case PK_CERTIFICATE_FIELD_CHANGED:
      return {
        ...state,
        pkCertificate: action.pkCertificate,
        pkCertificateFile: undefined
      }
    case PK_CERTIFICATE_FILE_LOADED:
      return {
        ...state,
        pkCertificateFile: action.pkCertificateFile
      }
    case VOUCHER_EXPIRY_CHANGED:
      return {
        ...state,
        expiresAt: action.expiresAt
      }
    case OVERRIDE_FIELD_TOGGLED:
      return {
        ...state,
        override: !state.override
      }
    case SECURITY_PROFILE_FIELD_TOGGLED:
      return {
        ...state,
        securityProfile: !state.securityProfile,
        securityProfileMode: state.securityProfile
          ? null
          : SECURITY_PROFILE_MODES.DEFAULT,
        securityProfileSettings: {}
      }
    case SECURITY_PROFILE_MODE_CHANGED:
      return {
        ...state,
        securityProfileMode: action.securityProfileMode,
        securityProfileSettings:
          action.securityProfileMode === state.securityProfileMode
            ? state.securityProfileSettings
            : {}
      }
    case SECURITY_PROFILE_SETTING_CHANGED:
      let securityProfileSettings = { ...state.securityProfileSettings }
      if (action.profile in securityProfileSettings) {
        if (action.value === null)
          delete securityProfileSettings[action.profile]
        else securityProfileSettings[action.profile] = action.value
      } else securityProfileSettings[action.profile] = true
      return {
        ...state,
        securityProfileSettings
      }
    case OS_TYPE_FIELD_CHANGED:
      return {
        ...state,
        osType: action.osType
      }
    case VOUCHER_REQUESTED:
      return {
        ...state,
        loading: true
      }
    case VOUCHER_REQUEST_SUCCESS:
    case VOUCHER_REQUEST_FAILURE:
      return {
        ...state,
        loading: false
      }
    case RESET_REQUEST_FORM:
      return defaultState
    default:
      return state
  }
}
export default requestForm
