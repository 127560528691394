import './styles.scss'
import NavPortal from '../../Nav/NavPortal'
import BulkOperations from '../../shared/BulkOperations'
import Guard from '../../Guard'
import { VoucherPermissions } from '../../Guard/constants'

import { Button } from '@blueprintjs/core'

const HomeActionsView = ({
  resetFilters,
  openRequestForm,
  selectedVouchers,
  showReset
}) => {
  return (
    <NavPortal>
      <div className="home-actions-container">
        <BulkOperations selectedVouchers={selectedVouchers} />
        {showReset && (
          <Button
            className="reset-filters-button animate__animated animate__fadeInUp animate__fastest"
            title="Reset Filters"
            text="Reset Filters"
            icon="filter-remove"
            onClick={resetFilters}
          />
        )}
        <Guard
          permissions={[VoucherPermissions.REQUEST_VOUCHER]}
          deniedComponent={<></>}
        >
          <Button
            className="new-requests-button"
            title="Request New Vouchers"
            text="New Request"
            icon="plus"
            intent="success"
            onClick={openRequestForm}
          />
        </Guard>
      </div>
    </NavPortal>
  )
}

export default HomeActionsView
