export const errorMap = {
  INVALID_HEADER: 'Invalid Header Format',
  INVALID_TOKEN: 'Invalid Token Provided',
  INVALID_USER: 'Invalid User Account',
  DISABLED_USER: 'Your User Account is Disabled',
  INVALID_INVITE: 'No Approved Invitation Found',
  NO_ACCESS_ORG: 'You do not have access to this Organization',
  INVALID_ORG: 'The Organization that you are trying to access does not exist',
  DISABLED_ORG: 'The Organization that you are trying to access is disabled'
}
