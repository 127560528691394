import { Button, NumericInput } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

const PageJumper = ({
  currentPage,
  pageNumberChanged,
  disabled,
  hasNextPage
}) => {
  return (
    <div className="page-jumper">
      <Popover2
        content={
          <NumericInput
            min={1}
            large={true}
            leftIcon="arrow-top-right"
            onValueChange={pageNumberChanged}
            placeholder="Enter page #"
            value={currentPage}
            max={hasNextPage ? undefined : currentPage}
          />
        }
        placement="right"
        disabled={disabled}
      >
        <Button
          icon="document"
          text={`Page #${currentPage}`}
          disabled={disabled}
        />
      </Popover2>
    </div>
  )
}

export default PageJumper
