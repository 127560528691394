import './styles.scss'
import { allowedFileTypes } from './constants'

import { useCallback, useState } from 'react'
import {
  Button,
  FileInput,
  Icon,
  Spinner,
  TagInput,
  TextArea
} from '@blueprintjs/core'
import classNames from 'classnames'
import { Tooltip2 } from '@blueprintjs/popover2'

const Field = ({
  id,
  name,
  icon,
  placeholder,
  className,
  fieldChanged,
  fileInputChanged,
  value,
  selectedFile,
  isProcessing,
  onClearAll,
  required,
  disabled,
  info
}) => {
  const [dragHighlight, setDragHighlight] = useState(false)
  const onDrop = useCallback(
    (e, id) => {
      e.preventDefault()
      setDragHighlight(false)
      fileInputChanged(e, id)
    },
    [fileInputChanged]
  )
  return (
    <div
      className={classNames('field', {
        [className]: className
      })}
    >
      <div className="field-header">
        <div className="field-header-main">
          <Icon className="field-header-main-icon" icon={icon} />
          <div className="field-header-main-title">{name}</div>
          {required && (
            <div className="field-header-main-required" title="Required">
              *
            </div>
          )}
          {info && (
            <Tooltip2
              content={info}
              placement="top-start"
              popoverClassName="field-header-main-tooltip"
              compact
              minimal
            >
              <Icon
                className="field-header-main-info"
                icon="info-sign"
                intent="primary"
                size={14}
              />
            </Tooltip2>
          )}
        </div>
        <div className="field-header-actions">
          {isProcessing === id && (
            <Spinner
              className="field-header-actions-spinner"
              intent="primary"
              size={15}
            />
          )}
          {value?.length > 0 && (
            <Button
              title="Clear All"
              icon="cross"
              onClick={() => onClearAll(id)}
              className="field-header-actions-clear animate__animated animate__zoomIn animate__fastest"
              intent="danger"
              minimal
              small
            />
          )}
          <FileInput
            className="field-header-actions-file-input"
            onInputChange={(e) => fileInputChanged(e, id)}
            inputProps={{ accept: allowedFileTypes[id] }}
            text={selectedFile?.name ?? 'Choose a file'}
            hasSelection={selectedFile?.name}
            disabled={disabled}
          />
        </div>
      </div>
      {id === 'serialNumbers' ? (
        <TagInput
          inputProps={{
            name: id,
            onDragEnter: () => setDragHighlight(true),
            onDragLeave: () => setDragHighlight(false),
            onDrop: (e) => onDrop(e, id)
          }}
          inputValue={undefined}
          values={value}
          className={classNames('field-value taginput', {
            'drag-highlight': dragHighlight
          })}
          placeholder={placeholder}
          onChange={(value) => fieldChanged(value, id)}
          disabled={disabled}
          addOnBlur
        />
      ) : (
        <TextArea
          name={id}
          onDragEnter={() => setDragHighlight(true)}
          onDragLeave={() => setDragHighlight(false)}
          onDrop={(e) => onDrop(e, id)}
          className={classNames('field-value textarea', {
            'drag-highlight': dragHighlight && !disabled
          })}
          placeholder={placeholder}
          onChange={(e) => fieldChanged(e.target.value, id)}
          value={value}
          disabled={disabled}
        />
      )}
    </div>
  )
}

export default Field
