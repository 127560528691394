import Toast from '../shared/Toast'

export const TOKENS_REQUESTED = 'TOKENS_REQUESTED'
export const TOKENS_REQUEST_SUCCESS = 'TOKENS_REQUEST_SUCCESS'
export const TOKENS_REQUEST_FAILURE = 'TOKENS_REQUEST_FAILURE'
export const TOKEN_GENERATE_REQUESTED = 'TOKEN_GENERATE_REQUESTED'
export const TOKEN_GENERATE_SUCCESS = 'TOKEN_GENERATE_SUCCESS'
export const TOKEN_GENERATE_FAILURE = 'TOKEN_GENERATE_FAILURE'
export const TOKEN_REVOKE_REQUESTED = 'TOKEN_REVOKE_REQUESTED'
export const TOKEN_REVOKE_SUCCESS = 'TOKEN_REVOKE_SUCCESS'
export const TOKEN_REVOKE_FAILURE = 'TOKEN_REVOKE_FAILURE'

const tokensRequested = () => {
  return {
    type: TOKENS_REQUESTED
  }
}

const tokensRequestSuccess = (tokens) => {
  return {
    type: TOKENS_REQUEST_SUCCESS,
    tokens
  }
}

const tokensRequestFailure = (error) => {
  return {
    type: TOKENS_REQUEST_FAILURE,
    error
  }
}

const tokenGenerateRequested = (name, expiresAt) => {
  return {
    type: TOKEN_GENERATE_REQUESTED,
    name,
    expiresAt
  }
}

const tokenGenerateSuccess = (token) => {
  return {
    type: TOKEN_GENERATE_SUCCESS,
    token
  }
}

const tokenGenerateFailure = (error) => {
  return {
    type: TOKEN_GENERATE_FAILURE,
    error
  }
}

const tokenRevokeRequested = () => {
  return {
    type: TOKEN_REVOKE_REQUESTED
  }
}

const tokenRevokeSuccess = (id) => {
  return {
    type: TOKEN_REVOKE_SUCCESS,
    id
  }
}

const tokenRevokeFailure = (id, error) => {
  return {
    type: TOKEN_REVOKE_FAILURE,
    id,
    error
  }
}

export const fetchTokens = () => async (dispatch) => {
  dispatch(tokensRequested())

  try {
    const response = await fetch('/api/auth/tokens', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(tokensRequestSuccess(json.data.tokens))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(tokensRequestFailure(e))
    Toast.show({
      message: `Failed to load tokens - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(fetchTokens())
      }
    })
  }
}

export const generateToken =
  (name, expiresAt, successCallback) => async (dispatch) => {
    dispatch(tokenGenerateRequested(name, expiresAt))
    try {
      const response = await fetch('/api/auth/token', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          expires_at: expiresAt
        })
      })
      const json = await response.json()
      if (json.ok) {
        dispatch(tokenGenerateSuccess(json.data))
        successCallback(json.data)
      } else throw new Error(json.error.msg)
    } catch (e) {
      dispatch(tokenGenerateFailure(e))
      Toast.show({
        message: `Failed to generate token - ${e}`,
        intent: 'danger',
        action: {
          text: 'Try again',
          icon: 'repeat',
          onClick: () => dispatch(generateToken(name, expiresAt))
        }
      })
    }
  }

export const revokeToken = (id, isExpired) => async (dispatch) => {
  dispatch(tokenRevokeRequested(id))
  try {
    const response = await fetch(`/api/auth/token/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(tokenRevokeSuccess(id))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(tokenRevokeFailure(id, e))
    Toast.show({
      message: `Failed to ${isExpired ? 'remove' : 'revoke'} token - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(revokeToken(id))
      }
    })
  }
}
