import Toast from '../shared/Toast'
import { readCookies, removeCookies } from '../../utils'

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

const loginRequested = (token) => {
  return {
    type: LOGIN_REQUESTED,
    token
  }
}

export const loginSuccess = (user, consented) => {
  return {
    type: LOGIN_SUCCESS,
    user,
    consented
  }
}

const loginFailed = (error) => {
  return {
    type: LOGIN_FAILED,
    error
  }
}

const logoutRequested = () => {
  return {
    type: LOGOUT_REQUESTED
  }
}

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  }
}

const logoutFailed = (error) => {
  return {
    type: LOGOUT_FAILED,
    error
  }
}

export const login = (token, navigate) => async (dispatch) => {
  try {
    dispatch(loginRequested(token))
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    })
    if (response.redirected) {
      window.location.replace(response.url)
      return
    }
    const json = await response.json()
    if (json.ok && json.data.success) {
      const { user, consented } = readCookies()
      if (!consented) {
        navigate('/user-agreement')
        dispatch(loginSuccess(user, consented))
        return
      }
      dispatch(loginSuccess(user, consented))
      const redirectURL = localStorage.getItem('redirectURL')
      if (redirectURL) {
        try {
          navigate(redirectURL)
        } catch {
          navigate('/home')
        }
        localStorage.removeItem('redirectURL')
      } else navigate('/home')
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(loginFailed(e))
    Toast.show({
      message: `Failed to login - ${e}`,
      intent: 'danger',
      action: {
        text: 'Retry',
        icon: 'repeat',
        onClick: () => dispatch(login(token, navigate))
      }
    })
  }
}

export const logout = (navigate) => async (dispatch) => {
  try {
    dispatch(logoutRequested())
    const response = await fetch('/api/auth/logout', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok && json.data.success) {
      removeCookies()
      dispatch(logoutSuccess())

      if (process.env.NODE_ENV === 'production') {
        window.location.assign(
          `https://www.cisco.com/autho/logout.html?ReturnUrl=${window.location.origin}`
        )
      } else {
        navigate('/')
      }
      Toast.show({ message: `You're now logged out`, intent: 'primary' })
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(logoutFailed(e))
    Toast.show({
      message: `Failed to logout - ${e}`,
      intent: 'danger',
      action: {
        text: 'Retry',
        icon: 'repeat',
        onClick: () => dispatch(logout(navigate))
      }
    })
  }
}
