import {
  VOUCHER_DETAIL_REQUESTED,
  VOUCHER_DETAIL_REQUEST_FAILURE,
  VOUCHER_DETAIL_REQUEST_SUCCESS
} from './actions'
import relatedVouchers from './RelatedVouchers/reducers'

import { combineReducers } from 'redux'

const defaultState = {}

const detail = (state = defaultState, action) => {
  switch (action.type) {
    case VOUCHER_DETAIL_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case VOUCHER_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        voucher: action.voucher
      }
    case VOUCHER_DETAIL_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

const voucher = combineReducers({
  detail,
  relatedVouchers
})

export default voucher
