// import { request } from './testConstants'
import Toast from '../shared/Toast'

import download from 'downloadjs'
import { getDownloadMeta } from '../../utils'

export const REQUEST_DETAIL_REQUESTED = 'REQUEST_DETAIL_REQUESTED'
export const REQUEST_DETAIL_REQUEST_SUCCESS = 'REQUEST_DETAIL_REQUEST_SUCCESS'
export const REQUEST_DETAIL_REQUEST_FAILURE = 'REQUEST_DETAIL_REQUEST_FAILURE'
export const REQUEST_ARCHIVE_REQUESTED = 'REQUEST_ARCHIVE_REQUESTED'
export const REQUEST_ARCHIVE_SUCCESS = 'REQUEST_ARCHIVE_SUCCESS'
export const REQUEST_ARCHIVE_FAILURE = 'REQUEST_ARCHIVE_FAILURE'
export const REQUEST_DOWNLOAD_REQUESTED = 'REQUEST_DOWNLOAD_REQUESTED'
export const REQUEST_DOWNLOAD_SUCCESS = 'REQUEST_DOWNLOAD_SUCCESS'
export const REQUEST_DOWNLOAD_FAILURE = 'REQUEST_DOWNLOAD_FAILURE'
export const RESET_REQUEST_DATA = 'RESET_REQUEST_DATA'

const requestDetailRequested = () => {
  return {
    type: REQUEST_DETAIL_REQUESTED
  }
}

const requestDetailRequestSuccess = (request) => {
  return {
    type: REQUEST_DETAIL_REQUEST_SUCCESS,
    request
  }
}

const requestDetailRequestFailure = (id, error) => {
  return {
    type: REQUEST_DETAIL_REQUEST_FAILURE,
    id,
    error
  }
}

const requestArchiveRequested = () => {
  return {
    type: REQUEST_ARCHIVE_REQUESTED
  }
}

const requestArchiveSuccess = (id, vouchers) => {
  return {
    type: REQUEST_ARCHIVE_SUCCESS,
    id,
    vouchers
  }
}

const requestArchiveFailure = (id, error) => {
  return {
    type: REQUEST_ARCHIVE_FAILURE,
    id,
    error
  }
}

const requestDownloadRequested = () => {
  return {
    type: REQUEST_DOWNLOAD_REQUESTED
  }
}

const requestDownloadSuccess = (id) => {
  return {
    type: REQUEST_DOWNLOAD_SUCCESS,
    id
  }
}

const requestDownloadFailure = (id, error) => {
  return {
    type: REQUEST_DOWNLOAD_FAILURE,
    id,
    error
  }
}

export const resetRequestData = () => {
  return {
    type: RESET_REQUEST_DATA
  }
}

export const fetchRequestDetail = (id) => async (dispatch) => {
  dispatch(requestDetailRequested())

  // setTimeout(() => {
  //   dispatch(requestDetailRequestSuccess(request))
  // }, 500)

  try {
    const response = await fetch(`/api/request/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(requestDetailRequestSuccess(json.request))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(requestDetailRequestFailure(id, e))
    Toast.show({
      message: `Failed to fetch request data ${id} - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(fetchRequestDetail(id))
      }
    })
  }
}

export const archiveRequest = (id) => async (dispatch) => {
  dispatch(requestArchiveRequested())
  try {
    const response = await fetch(`/api/request/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(requestArchiveSuccess(id, json.archived))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(requestArchiveFailure(id, e))
    Toast.show({
      message: `Failed to archive request ${id} - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(archiveRequest(id))
      }
    })
  }
}

export const downloadRequest = (id) => async (dispatch) => {
  dispatch(requestDownloadRequested())
  try {
    const response = await fetch(`/api/download/request/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (response.ok) {
      const blob = await response.blob()
      const { fileName, mimeType } = getDownloadMeta(response, blob)
      download(blob, fileName, mimeType)
      dispatch(requestDownloadSuccess(id))
    } else {
      const json = await response.json()
      throw new Error(json.error.msg)
    }
  } catch (e) {
    dispatch(requestDownloadFailure(id, e))
    Toast.show({
      message: `Failed to download request ${id} - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(downloadRequest(id))
      }
    })
  }
}
