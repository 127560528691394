import {
  USER_PERMISSIONS_REQUESTED,
  USER_PERMISSIONS_REQUEST_FAILURE,
  USER_PERMISSIONS_REQUEST_SUCCESS,
  USER_PERMISSIONS_ROLES_REQUEST_SUCCESS,
  USER_PERMISSIONS_ROLE_CHANGED,
  USER_PERMISSIONS_ROLE_REMOVED,
  USER_PERMISSIONS_USERS_ADDED,
  USER_PERMISSIONS_ALL_ROLES_REMOVED
} from './actions'

const defaultState = { roles: [], users: [], selectedRole: [] }

const accessControl = (state = defaultState, action) => {
  switch (action.type) {
    case USER_PERMISSIONS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case USER_PERMISSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users
      }
    case USER_PERMISSIONS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case USER_PERMISSIONS_ROLES_REQUEST_SUCCESS:
      return {
        ...state,
        roles: action.roles
      }
    case USER_PERMISSIONS_USERS_ADDED:
      let newUsersMap = action.users.reduce((users, user) => {
        return { ...users, [user.user_id]: user }
      }, {})

      let result = state.users.reduce((users, user) => {
        if (action.users.find((item) => item.user_id === user.user_id)) {
          delete newUsersMap[user.user_id]
          const roleExists = user.roles?.find(
            (role) => role.id === action.role.id
          )
          if (!roleExists) {
            return [
              ...users,
              {
                ...user,
                roles: [...user.roles, action.role],
                updated: true
              }
            ]
          } else return [...users, user]
        } else return [...users, user]
      }, [])

      Object.values(newUsersMap).forEach((user) => {
        result.unshift({
          ...user,
          roles: [action.role],
          updated: true
        })
      })

      return {
        ...state,
        users: result
      }
    case USER_PERMISSIONS_ALL_ROLES_REMOVED:
      return {
        ...state,
        users: state.users.reduce((users, user) => {
          if (user.user_id === action.user.user_id) {
            return [
              ...users,
              {
                ...user,
                roles: user.roles.filter((role) =>
                  action.roles.includes(role.id)
                )
              }
            ]
          } else return [...users, user]
        }, [])
      }
    case USER_PERMISSIONS_ROLE_REMOVED:
      return {
        ...state,
        users: state.users.reduce((users, user) => {
          if (user.user_id === action.user.user_id) {
            return [
              ...users,
              {
                ...user,
                roles: user.roles.filter((role) => role.id !== action.role.id)
              }
            ]
          } else return [...users, user]
        }, [])
      }
    case USER_PERMISSIONS_ROLE_CHANGED:
      return {
        ...state,
        selectedRole: action.role
      }
    default:
      return state
  }
}
export default accessControl
