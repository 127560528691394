import { resetUserSession } from '../../utils'
import Toast from '../shared/Toast'

export const MY_ORGANIZATIONS_REQUESTED = 'MY_ORGANIZATIONS_REQUESTED'
export const MY_ORGANIZATIONS_REQUEST_SUCCESS =
  'MY_ORGANIZATIONS_REQUEST_SUCCESS'
export const MY_ORGANIZATIONS_REQUEST_FAILURE =
  'MY_ORGANIZATIONS_REQUEST_FAILURE'
export const ORGANIZATION_SWITCH_REQUESTED = 'ORGANIZATION_SWITCH_REQUESTED'
export const ORGANIZATION_SWITCH_SUCCESS = 'ORGANIZATION_SWITCH_SUCCESS'
export const ORGANIZATION_SWITCH_FAILURE = 'ORGANIZATION_SWITCH_FAILURE'

const myOrganizationsRequested = () => {
  return {
    type: MY_ORGANIZATIONS_REQUESTED
  }
}

const myOrganizationsRequestSuccess = (
  organizations,
  currentOrganization,
  meta
) => {
  return {
    type: MY_ORGANIZATIONS_REQUEST_SUCCESS,
    organizations,
    currentOrganization,
    meta
  }
}

const myOrganizationsRequestFailure = () => {
  return {
    type: MY_ORGANIZATIONS_REQUEST_FAILURE
  }
}

const organizationSwitchRequested = () => {
  return {
    type: ORGANIZATION_SWITCH_REQUESTED
  }
}

const organizationSwitchSuccess = (organization) => {
  return {
    type: ORGANIZATION_SWITCH_SUCCESS,
    currentOrganization: organization
  }
}

const organizationSwitchFailure = (organization, currentOrganization) => {
  return {
    type: ORGANIZATION_SWITCH_FAILURE,
    organization,
    currentOrganization
  }
}

export const fetchMyOrganizations = () => async (dispatch) => {
  dispatch(myOrganizationsRequested())
  try {
    const response = await fetch('/api/auth/organization/my', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()

    if (json.ok) {
      const currentOrg = json.data.org_data.find(
        (org) => org.org_id === json.data.current_org
      )
      const meta = {
        userID: json.data.user_id,
        username: json.data.username,
        email: json.data.email
      }
      dispatch(
        myOrganizationsRequestSuccess(json.data.org_data, currentOrg, meta)
      )
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(myOrganizationsRequestFailure())
  }
}

export const switchOrganization =
  (organization) => async (dispatch, getState) => {
    dispatch(organizationSwitchRequested())
    try {
      const response = await fetch('/api/auth/organization/switch', {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          organization: organization.org_id
        })
      })
      const json = await response.json()
      if (json.ok) {
        Toast.show({
          message: `Switched to organization ${organization.org_name}`,
          intent: 'success'
        })
        dispatch(organizationSwitchSuccess(organization))
        resetUserSession()
      } else throw new Error(json.error.msg)
    } catch (e) {
      Toast.show({
        message: `Failed to switch organization - ${e}`,
        intent: 'danger',
        action: {
          text: 'Try again',
          icon: 'repeat',
          onClick: () => dispatch(switchOrganization(organization))
        }
      })
      dispatch(
        organizationSwitchFailure(
          organization,
          getState().orgSwitcher.currentOrganization
        )
      )
    }
  }
