import { login } from './actions'
import LoginView from './LoginView'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const Login = () => {
  const [token, setToken] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.login)

  const loginClicked = () => {
    dispatch(login(token, navigate))
  }

  return (
    <LoginView
      token={token}
      setToken={setToken}
      loginClicked={loginClicked}
      loading={loading}
    />
  )
}

export default Login
