import { allowedFileTypes, OS_TYPES } from './constants'
import RequestFormView from './RequestFormView'
import Toast from '../shared/Toast'

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  certificateFieldChanged,
  certificateFileLoaded,
  pkCertificateFieldChanged,
  pkCertificateFileLoaded,
  requestVoucher,
  serialNumbersFieldChanged,
  serialNumbersFileLoaded,
  overrideFieldToggled,
  osTypeFieldChanged,
  voucherExpiryChanged,
  securityProfileFieldToggled,
  toggleUsePreUploadedCertificate,
  fetchCertificates,
  setSelectedCertificate
} from './actions'

const RequestForm = ({ callback }) => {
  const {
    certificate,
    certificateFile,
    serialNumbers,
    serialNumbersFile,
    pkCertificate,
    pkCertificateFile,
    override,
    osType,
    expiresAt,
    securityProfile,
    loading,
    invalid,
    duplicate
  } = useSelector((state) => state.requestForm)
  const [isProcessing, setIsProcessing] = useState(null)

  const {
    usePreUploadedCertificate,
    preUploadedCertificates,
    certificatesLoading,
    selectedCertificate
  } = useSelector((state) => state.requestForm)

  const dispatch = useDispatch()

  const readFile = useCallback((inputFile) => {
    const fileReader = new FileReader()
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort()
        reject(new DOMException('Error parsing file'))
      }
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.readAsText(inputFile)
    })
  }, [])

  const fileInputChanged = useCallback(
    async (e, id) => {
      const source = e?.target?.files?.[0] ?? e?.dataTransfer?.files?.[0]
      if (source) {
        try {
          if (
            source.type?.includes('text') ||
            allowedFileTypes[id].some((item) => source.name?.endsWith(item))
          ) {
            setIsProcessing(id)
            const file = await readFile(source)
            switch (id) {
              case 'certificate':
                dispatch(certificateFieldChanged(file))
                dispatch(certificateFileLoaded(source))
                break
              case 'pkCertificate':
                dispatch(pkCertificateFieldChanged(file))
                dispatch(pkCertificateFileLoaded(source))
                break
              case 'serialNumbers':
                dispatch(serialNumbersFieldChanged(file.split(/[,\n\r]/)))
                dispatch(serialNumbersFileLoaded(source))
                break
              default:
                break
            }
            setIsProcessing(null)
          } else {
            Toast.show({
              message: 'Please use a valid file',
              intent: 'warning'
            })
          }
        } catch (e) {
          Toast.show({
            message: 'Error loading file',
            intent: 'danger'
          })
        }
      }
    },
    [dispatch, readFile]
  )

  const fieldChanged = useCallback(
    (value, id) => {
      switch (id) {
        case 'certificate':
          dispatch(certificateFieldChanged(value))
          break
        case 'pkCertificate':
          dispatch(pkCertificateFieldChanged(value))
          break
        case 'serialNumbers':
          dispatch(serialNumbersFieldChanged(value))
          break
        default:
          break
      }
    },
    [dispatch]
  )

  const onSubmit = useCallback(() => {
    dispatch(requestVoucher(callback))
  }, [dispatch, callback])

  const _overrideFieldToggled = useCallback(() => {
    dispatch(overrideFieldToggled())
  }, [dispatch])

  const _securityProfileFieldToggled = useCallback(() => {
    dispatch(securityProfileFieldToggled())
  }, [dispatch])

  const _osTypeFieldChanged = useCallback(
    (osType) => {
      dispatch(osTypeFieldChanged(osType))
    },
    [dispatch]
  )

  const _voucherExpiryChanged = useCallback(
    (expiresAt) => {
      dispatch(voucherExpiryChanged(expiresAt))
    },
    [dispatch]
  )

  const onClearAll = useCallback(
    (id) => {
      switch (id) {
        case 'certificate':
          dispatch(certificateFieldChanged(''))
          break
        case 'pkCertificate':
          dispatch(pkCertificateFieldChanged(''))
          break
        case 'serialNumbers':
          dispatch(serialNumbersFieldChanged([]))
          break
        default:
          break
      }
    },
    [dispatch]
  )

  const _toggleUsePreUploadedCertificate = useCallback(() => {
    dispatch(setSelectedCertificate(null))
    dispatch(certificateFieldChanged(''))
    if (!usePreUploadedCertificate) {
      dispatch(fetchCertificates())
    }
    dispatch(toggleUsePreUploadedCertificate())
  }, [dispatch, usePreUploadedCertificate])

  const _setSelectedCertificate = useCallback(
    (certificate) => {
      dispatch(setSelectedCertificate(certificate))
    },
    [dispatch]
  )

  useEffect(() => {
    if (pkCertificate && osType !== OS_TYPES.IOS_XR)
      dispatch(osTypeFieldChanged(OS_TYPES.IOS_XR))
  }, [dispatch, pkCertificate, osType])

  return (
    <RequestFormView
      fieldChanged={fieldChanged}
      certificate={certificate}
      serialNumbers={serialNumbers}
      pkCertificate={pkCertificate}
      override={override}
      osType={osType}
      fileInputChanged={fileInputChanged}
      certificateFile={certificateFile}
      serialNumbersFile={serialNumbersFile}
      pkCertificateFile={pkCertificateFile}
      overrideFieldToggled={_overrideFieldToggled}
      osTypeFieldChanged={_osTypeFieldChanged}
      expiresAt={expiresAt}
      voucherExpiryChanged={_voucherExpiryChanged}
      securityProfile={securityProfile}
      securityProfileFieldToggled={_securityProfileFieldToggled}
      onSubmit={onSubmit}
      isProcessing={isProcessing}
      loading={loading}
      invalid={invalid}
      duplicate={duplicate}
      onClearAll={onClearAll}
      usePreUploadedCertificate={usePreUploadedCertificate}
      toggleUsePreUploadedCertificate={_toggleUsePreUploadedCertificate}
      preUploadedCertificates={preUploadedCertificates}
      certificatesLoading={certificatesLoading}
      selectedCertificate={selectedCertificate}
      setSelectedCertificate={_setSelectedCertificate}
    />
  )
}

export default RequestForm
