import { SortMap, StatusFilterMap } from '../shared/Filters/constants'
// import { requests } from './testConstants'
import Toast from '../shared/Toast'

import Moment from 'moment-timezone'

export const REQUESTS_REQUESTED = 'REQUESTS_REQUESTED'
export const REQUESTS_REQUEST_SUCCESS = 'REQUESTS_REQUEST_SUCCESS'
export const REQUESTS_REQUEST_FAILURE = 'REQUESTS_REQUEST_FAILURE'
export const PREVIOUS_PAGE_REQUESTED = 'PREVIOUS_PAGE_REQUESTED'
export const NEXT_PAGE_REQUESTED = 'NEXT_PAGE_REQUESTED'
export const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED'
export const PAGE_NUMBER_CHANGED = 'PAGE_NUMBER_CHANGED'
export const RESET_REQUESTS_DATA = 'RESET_REQUESTS_DATA'

const requestsRequested = () => {
  return {
    type: REQUESTS_REQUESTED
  }
}

const requestsRequestSuccess = (requests, hasNextPage) => {
  return {
    type: REQUESTS_REQUEST_SUCCESS,
    requests,
    hasNextPage
  }
}

const requestsRequestFailure = (error) => {
  return {
    type: REQUESTS_REQUEST_FAILURE,
    error
  }
}

export const resetRequestsData = () => {
  return {
    type: RESET_REQUESTS_DATA
  }
}

export const previousPageRequested = () => {
  return {
    type: PREVIOUS_PAGE_REQUESTED
  }
}

export const nextPageRequested = () => {
  return {
    type: NEXT_PAGE_REQUESTED
  }
}

export const pageSizeChanged = (pageSize) => {
  return {
    type: PAGE_SIZE_CHANGED,
    pageSize
  }
}

export const pageNumberChanged = (pageNumber) => {
  return {
    type: PAGE_NUMBER_CHANGED,
    pageNumber
  }
}

export const fetchRequests = () => async (dispatch, getState) => {
  const {
    search,
    sortType,
    sortOrder,
    selectedStatus,
    selectedPDCOrganizations,
    selectedDateRange
  } = getState().filters
  const { pageSize, currentPage } = getState().requests

  dispatch(requestsRequested())

  let params = new URLSearchParams()
  if (search) params.append('query', search)
  params.append('sort_order', sortOrder ? 'desc' : 'asc')
  params.append('sort_type', SortMap[sortType])
  selectedStatus.forEach((status) =>
    params.append('status', StatusFilterMap[status])
  )
  selectedPDCOrganizations.forEach((pdcOrganization) =>
    params.append('pdc_organization', pdcOrganization)
  )
  params.append('page_size', pageSize)
  params.append('page', currentPage)

  const [startDate, endDate] = selectedDateRange

  if (startDate) {
    params.append('start_date', Moment(startDate).startOf('day').toISOString())
    params.append(
      'end_date',
      endDate
        ? Moment(endDate).endOf('day').toISOString()
        : Moment(startDate).endOf('day').toISOString()
    )
  }

  // setTimeout(() => {
  //   dispatch(requestsRequestSuccess(requests, false))
  // }, 500)

  try {
    const response = await fetch(`/api/requests?${params.toString()}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      dispatch(requestsRequestSuccess(json.requests, json.has_next_page))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(requestsRequestFailure(e))
    Toast.show({
      message: `Failed to load requests - ${e}`,
      intent: 'danger',
      action: {
        text: 'Try again',
        icon: 'repeat',
        onClick: () => dispatch(fetchRequests())
      }
    })
  }
}
