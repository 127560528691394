import Toast from './components/shared/Toast'

export const AVAILABLE_PERMISSIONS_REQUESTED = 'AVAILABLE_PERMISSIONS_REQUESTED'
export const AVAILABLE_PERMISSIONS_REQUEST_SUCCESS =
  'AVAILABLE_PERMISSIONS_REQUEST_SUCCESS'
export const AVAILABLE_PERMISSIONS_REQUEST_FAILURE =
  'AVAILABLE_PERMISSIONS_REQUEST_FAILURE'
export const ALERTS_REQUESTED = 'ALERTS_REQUESTED'
export const ALERTS_REQUEST_SUCCESS = 'ALERTS_REQUEST_SUCCESS'
export const ALERTS_REQUEST_FAILURE = 'ALERTS_REQUEST_FAILURE'

const availablePermissionsRequested = () => {
  return {
    type: AVAILABLE_PERMISSIONS_REQUESTED
  }
}

const availablePermissionsRequestSuccess = (permissions, permissionsList) => {
  return {
    type: AVAILABLE_PERMISSIONS_REQUEST_SUCCESS,
    permissions,
    permissionsList
  }
}

const availablePermissionsRequestFailure = (error) => {
  return {
    type: AVAILABLE_PERMISSIONS_REQUEST_FAILURE,
    error
  }
}

const alertsRequested = () => {
  return {
    type: ALERTS_REQUESTED
  }
}

const alertsRequestSuccess = (alert) => {
  return {
    type: ALERTS_REQUEST_SUCCESS,
    alert
  }
}

const alertsRequestFailure = (error) => {
  return {
    type: ALERTS_REQUEST_FAILURE,
    error
  }
}

export const fetchAvailablePermissions = () => async (dispatch) => {
  dispatch(availablePermissionsRequested())

  try {
    const response = await fetch('/api/users/permissions/my', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.ok) {
      const permissionsList = json.data
      const permissions = permissionsList.reduce((all, module) => {
        const permissionsMap = module.permissions?.reduce((all, permission) => {
          return { ...all, [permission]: true }
        }, {})
        return { ...all, [module.module]: permissionsMap }
      }, {})
      dispatch(availablePermissionsRequestSuccess(permissions, permissionsList))
    } else throw new Error(json.error.msg)
  } catch (e) {
    dispatch(availablePermissionsRequestFailure(e))
    Toast.show({
      message: `Failed to load available permissions - ${e}`,
      intent: 'danger'
    })
  }
}

export const fetchAlerts = () => async (dispatch) => {
  dispatch(alertsRequested())
  try {
    const url =
      process.env.NODE_ENV !== 'production'
        ? 'https://masa.cisco.com/alerts'
        : '/alerts'
    const response = await fetch(url)
    const json = await response.json()
    dispatch(alertsRequestSuccess(json))
  } catch (e) {
    dispatch(alertsRequestFailure(e))
  }
}
