import './styles.scss'
import { SortTypes, StatusFilterTypes } from './constants'
import SearchBar from '../SearchBar'
import SortBy from '../SortBy'
import Selector from '../Selector'
import DateRangePicker from '../DateRangePicker'

import classNames from 'classnames'
import { useEffect, useState } from 'react'

const Search = ({ items, search, searchChanged }) => {
  const [value, setValue] = useState(search)

  useEffect(() => {
    if (search === '') setValue(search)
  }, [search])

  return (
    <SearchBar
      value={value}
      count={items?.length}
      countLabel="voucher"
      searchChanged={(e) => {
        const value = e.target.value
        setValue(value)
        searchChanged(value)
      }}
      small
    />
  )
}

const FiltersView = ({
  loading,
  items,
  searchChanged,
  search,
  sortOrder,
  sortType,
  sortOrderChanged,
  sortTypeChanged,
  pdcOrganizations,
  selectedPDCOrganizations,
  selectedStatus,
  statusFilterChanged,
  pdcOrganizationFilterChanged,
  selectedDateRange,
  dateRangeChanged,
  compact
}) => {
  const sortTypes = compact
    ? Object.values(SortTypes).filter(
        (sortType) =>
          ![
            SortTypes.DATE_REQUESTED,
            SortTypes.REQUESTED_BY,
            SortTypes.PDC_ORGANIZATION
          ].includes(sortType)
      )
    : Object.values(SortTypes).filter(
        (sortType) => ![SortTypes.DATE_CREATED].includes(sortType)
      )

  const statusFilterTypes = Object.values(StatusFilterTypes)

  return (
    <div
      className={classNames('shared-filters-container', {
        compact
      })}
    >
      <div className="shared-filters-selectors">
        <Selector
          icon="filter"
          prefix=""
          name="Status"
          items={statusFilterTypes}
          selectedItems={selectedStatus}
          onItemSelect={statusFilterChanged}
          disabled={loading}
          highlightWhenSelected
        />
        {!compact && (
          <Selector
            icon="diagram-tree"
            prefix=""
            name="PDC Organization"
            pluralSuffix=""
            items={pdcOrganizations}
            selectedItems={selectedPDCOrganizations}
            onItemSelect={pdcOrganizationFilterChanged}
            disabled={loading}
            highlightWhenSelected
            multi
          />
        )}
      </div>
      <Search items={items} search={search} searchChanged={searchChanged} />
      <div className="shared-filters-date-sort">
        {!compact && (
          <DateRangePicker
            value={selectedDateRange}
            onChange={dateRangeChanged}
            disabled={loading}
          />
        )}
        <SortBy
          types={sortTypes}
          selectedType={sortType}
          desc={sortOrder}
          typeChanged={sortTypeChanged}
          orderChanged={sortOrderChanged}
          disabled={loading}
        />
      </div>
    </div>
  )
}

export default FiltersView
