import {
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGOUT_FAILED,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCESS
} from './actions'

const defaultState = {}

const login = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
    case LOGOUT_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case LOGIN_FAILED:
    case LOGOUT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}
export default login
