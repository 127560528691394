import { acceptUserAgreement } from './actions'
import { resetUserSession } from '../../utils'
import UserAgreementView from './UserAgreementView'

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

const UserAgreement = () => {
  const dispatch = useDispatch()

  const accept = useCallback(() => dispatch(acceptUserAgreement()), [dispatch])

  return <UserAgreementView accept={accept} logout={resetUserSession} />
}

export default UserAgreement
