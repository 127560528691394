import {
  REQUEST_DETAIL_REQUESTED,
  REQUEST_DETAIL_REQUEST_FAILURE,
  REQUEST_DETAIL_REQUEST_SUCCESS,
  REQUEST_ARCHIVE_SUCCESS,
  RESET_REQUEST_DATA
} from './actions'
import {
  VOUCHER_SELECTED,
  VOUCHER_DESELECTED,
  VOUCHER_MULTI_SELECTED,
  VOUCHER_MULTI_DESELECTED,
  RESET_VOUCHER_SELECTION,
  VOUCHER_ARCHIVE_SUCCESS,
  VOUCHER_BULK_ARCHIVE_SUCCESS
} from '../Voucher/actions'
import {
  archiveVoucher,
  deselectMultipleVouchers,
  deselectVoucher,
  selectMultipleVouchers,
  selectVoucher,
  bulkArchiveVouchers
} from '../Voucher/utils'

const defaultState = {
  selectedVouchers: {}
}

const request = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_DETAIL_REQUESTED:
      return {
        ...state,
        loading: true,
        error: null
      }
    case REQUEST_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        request: action.request
      }
    case REQUEST_DETAIL_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case REQUEST_ARCHIVE_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          vouchers: bulkArchiveVouchers(
            state.request?.vouchers,
            action.vouchers
          )
        }
      }
    case VOUCHER_ARCHIVE_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          vouchers: archiveVoucher(state.request?.vouchers, action.id)
        }
      }
    case VOUCHER_BULK_ARCHIVE_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          vouchers: bulkArchiveVouchers(
            state.request?.vouchers,
            action.vouchers
          )
        }
      }
    case VOUCHER_SELECTED:
      return {
        ...state,
        selectedVouchers: selectVoucher(state.selectedVouchers, action.id)
      }
    case VOUCHER_DESELECTED:
      return {
        ...state,
        selectedVouchers: deselectVoucher(state.selectedVouchers, action.id)
      }
    case VOUCHER_MULTI_SELECTED:
      return {
        ...state,
        selectedVouchers: selectMultipleVouchers(
          state.selectedVouchers,
          action.vouchers
        )
      }
    case VOUCHER_MULTI_DESELECTED:
      return {
        ...state,
        selectedVouchers: deselectMultipleVouchers(
          state.selectedVouchers,
          action.vouchers
        )
      }
    case RESET_VOUCHER_SELECTION:
      return {
        ...state,
        selectedVouchers: {}
      }
    case RESET_REQUEST_DATA:
      return defaultState
    default:
      return state
  }
}
export default request
