import { Button, Classes, Icon } from '@blueprintjs/core'
import classNames from 'classnames'
import './styles.scss'

const ArchiveConfirmation = ({
  className,
  onConfirm,
  disabled,
  confirmText,
  voucherCount,
  request = false
}) => {
  return (
    <div
      className={classNames('archive-confirmation-container', {
        [className]: className
      })}
    >
      <div className="archive-confirmation-warning">
        <Icon
          icon="warning-sign"
          className="archive-confirmation-warning-icon"
          intent="warning"
          size={24}
        />
        <div className="archive-confirmation-warning-text">
          The&nbsp;
          {request ? 'vouchers in this request' : voucherCount ?? 'voucher'}
          &nbsp;will be deleted permanently and will no longer be available for
          download
        </div>
      </div>
      <Button
        className={classNames('archive-confirmation-cancel-button', {
          [Classes.POPOVER_DISMISS]: true
        })}
        icon="cross"
        text="Cancel"
        minimal
        small
      />
      <Button
        className="archive-confirmation-confirm-button"
        onClick={onConfirm}
        disabled={disabled}
        icon="tick"
        text={`Confirm Archive ${confirmText}`}
        intent="danger"
      />
    </div>
  )
}

export default ArchiveConfirmation
