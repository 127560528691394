import { Button, ButtonGroup, Switch, Tag } from '@blueprintjs/core'
import {
  SECURITY_PROFILES,
  SECURITY_PROFILES_DISPLAY_MAP,
  SECURITY_PROFILE_MODES
} from './constants'
import './styles.scss'

const SecurityProfileSelectorView = ({
  securityProfileMode,
  securityProfileModeChanged,
  securityProfileSettings,
  securityProfileSettingChanged
}) => {
  return (
    <div className="security-profile-selector-container">
      <ButtonGroup className="security-profile-presets animate__animated animate__fadeInDown animate__fastest">
        {Object.values(SECURITY_PROFILE_MODES).map((mode) => (
          <Button
            key={`security-profile-mode-${mode}`}
            active={securityProfileMode === mode}
            onClick={() => securityProfileModeChanged(mode)}
            className="security-profile-mode"
            small
          >
            {mode}
          </Button>
        ))}
      </ButtonGroup>
      {securityProfileMode === SECURITY_PROFILE_MODES.CUSTOM && (
        <>
          <div className="custom-security-profile-mode-header">
            Toggle the available settings below to override system defaults
          </div>
          <div className="custom-security-profile-mode-settings animate__animated animate__fadeInUp animate__faster">
            {Object.values(SECURITY_PROFILES).map((profile) => (
              <div
                className="security-profile"
                key={`security-profile-${profile}`}
              >
                <Switch
                  checked={profile in securityProfileSettings ? true : false}
                  onChange={() => securityProfileSettingChanged(profile, null)}
                />
                <div className="security-profile-header">
                  {SECURITY_PROFILES_DISPLAY_MAP[profile]}
                </div>
                {profile in securityProfileSettings ? (
                  <>
                    {/* <Switch
                      checked={securityProfileSettings?.[profile] === true}
                      onClick={() =>
                        securityProfileSettingChanged(
                          profile,
                          !securityProfileSettings?.[profile]
                        )
                      }
                      label={
                        securityProfileSettings?.[profile]
                          ? 'Enabled'
                          : 'Disabled'
                      }
                    /> */}
                    <ButtonGroup className="security-profile-setting animate__animated animate__fadeInRight animate__fastest">
                      <Button
                        active={securityProfileSettings?.[profile] === true}
                        intent={
                          securityProfileSettings?.[profile]
                            ? 'success'
                            : 'default'
                        }
                        icon="tick"
                        small
                        onClick={() =>
                          securityProfileSettingChanged(profile, true)
                        }
                        text="Enable"
                      />
                      <Button
                        active={securityProfileSettings?.[profile] === false}
                        intent={
                          securityProfileSettings?.[profile] === false
                            ? 'danger'
                            : 'default'
                        }
                        icon="cross"
                        small
                        onClick={() =>
                          securityProfileSettingChanged(profile, false)
                        }
                        text="Disable"
                      />
                    </ButtonGroup>
                  </>
                ) : (
                  <Tag
                    className="security-profile-setting animate__animated animate__zoomIn animate__fastest"
                    minimal
                    round
                  >
                    Default
                  </Tag>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default SecurityProfileSelectorView
