import React from 'react'
import { useSelector } from 'react-redux'

export const useGuard = (permissions) => {
  const availablePermissions = useSelector((state) => state.app.permissions)
  return permissions.every(
    (permission) => availablePermissions?.[permission.module]?.[permission.name]
  )
}

const Guard = ({ children, permissions, deniedProps, deniedComponent }) => {
  const hasAccess = useGuard(permissions)
  if (hasAccess) return children
  else return React.cloneElement(deniedComponent ?? children, deniedProps)
}

export default Guard
