import './styles.scss'

import { Breadcrumbs, Navbar, Text } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ text, link }) =>
  link ? <Link to={link}>{text}</Link> : text

const CurrentBreadcrumb = ({ text }) => <span>{text}</span>

const NavView = ({ title, subTitle }) => {
  return (
    <Navbar className="nav-container" fixedToTop>
      <Navbar.Group align="left" className="nav-header">
        <div className="nav-title">
          <Breadcrumbs
            className="nav-breadcrumbs"
            breadcrumbRenderer={Breadcrumb}
            currentBreadcrumbRenderer={CurrentBreadcrumb}
            items={title}
          />
        </div>
        <Text className="nav-subtitle" ellipsize>
          {subTitle}
        </Text>
      </Navbar.Group>
      <Navbar.Group align="right" id="nav-actions" className="nav-actions" />
    </Navbar>
  )
}

export default NavView
