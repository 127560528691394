import RequestsView from './RequestsView'
import {
  fetchRequests,
  pageSizeChanged,
  pageNumberChanged,
  nextPageRequested,
  previousPageRequested,
  resetRequestsData
} from './actions'
import { archiveRequest } from '../Request/actions'
import {
  downloadVoucher,
  archiveVoucher,
  voucherSelected,
  voucherDeselected,
  resetVoucherSelection,
  voucherMultiSelected,
  voucherMultiDeselected
} from '../Voucher/actions'
import {
  dateRangeChanged,
  pdcOrganizationFilterChanged,
  searchChanged,
  sortOrderChanged,
  sortTypeChanged,
  statusFilterChanged
} from '../shared/Filters/actions'
import {
  SortMap,
  SortTypes,
  StatusFilterMap
} from '../shared/Filters/constants'

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'

const Requests = () => {
  const {
    requests,
    loading,
    error,
    pageSize,
    currentPage,
    hasNextPage,
    selectedVouchers
  } = useSelector((state) => state.requests)
  const {
    search,
    sortType,
    sortOrder,
    selectedStatus,
    selectedPDCOrganizations,
    pdcOrganizations: availablePDCOrganizations,
    selectedDateRange
  } = useSelector((state) => state.filters)
  const [init, setInit] = useState(false)
  const { search: urlParams } = useLocation()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const _fetchRequests = useCallback(
    () => dispatch(fetchRequests()),
    [dispatch]
  )

  const _archiveRequest = useCallback(
    (id) => dispatch(archiveRequest(id)),
    [dispatch]
  )

  const _archiveVoucher = useCallback(
    (id) => dispatch(archiveVoucher(id)),
    [dispatch]
  )

  const _downloadVoucher = useCallback(
    (id, serialNumber) => dispatch(downloadVoucher(id, serialNumber)),
    [dispatch]
  )

  const selectVoucher = useCallback(
    (id) => dispatch(voucherSelected(id)),
    [dispatch]
  )

  const deselectVoucher = useCallback(
    (id) => dispatch(voucherDeselected(id)),
    [dispatch]
  )

  const selectMultipleVouchers = useCallback(
    (items) => dispatch(voucherMultiSelected(items)),
    [dispatch]
  )

  const deselectMultipleVouchers = useCallback(
    (items) => dispatch(voucherMultiDeselected(items)),
    [dispatch]
  )

  const _pageSizeChanged = useCallback(
    (size) => dispatch(pageSizeChanged(size)),
    [dispatch]
  )

  const _pageNumberChanged = useCallback(
    (page) => dispatch(pageNumberChanged(page)),
    [dispatch]
  )

  const _nextPageRequested = useCallback(
    () => dispatch(nextPageRequested()),
    [dispatch]
  )

  const _previousPageRequested = useCallback(
    () => dispatch(previousPageRequested()),
    [dispatch]
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const search = params.get('search')
    const sortOrder = params.get('sort_order')
    const sortType = params.get('sort_type')
    const pdcOrganizations = params.getAll('pdc_organization')
    const status = params.get('status')
    const startDate = params.get('start_date')
    const endDate = params.get('end_date')

    if (search) dispatch(searchChanged(search))

    if (sortOrder === 'asc') dispatch(sortOrderChanged(false))
    else dispatch(sortOrderChanged(true))

    if (sortType) {
      const sortKey = Object.keys(SortMap).find(
        (key) => SortMap[key] === sortType
      )
      if (sortKey) dispatch(sortTypeChanged(sortKey))
    } else dispatch(sortTypeChanged(SortTypes.DATE_REQUESTED))

    pdcOrganizations?.forEach((pdcOrganization) => {
      dispatch(pdcOrganizationFilterChanged(pdcOrganization))
    })

    if (status !== 'active') {
      const statusKey = Object.keys(StatusFilterMap).find(
        (key) => StatusFilterMap[key] === status
      )
      if (statusKey) dispatch(statusFilterChanged(statusKey))
    }

    if (startDate || endDate) {
      const start = new Date(startDate)
      const end = endDate ? new Date(endDate) : new Date()
      dispatch(dateRangeChanged([start, end]))
    }

    setInit(true)
  }, [dispatch, setInit])

  useEffect(() => {
    if (availablePDCOrganizations?.length) {
      const params = new URLSearchParams(window.location.search)

      const pdcOrganizations = params.getAll('pdc_organization')

      pdcOrganizations?.forEach((pdcOrganization) => {
        if (!availablePDCOrganizations.includes(pdcOrganization))
          dispatch(pdcOrganizationFilterChanged(pdcOrganization))
      })
    }
  }, [dispatch, availablePDCOrganizations])

  useEffect(() => {
    if (init) {
      const params = new URLSearchParams()

      if (search) params.append('search', search)

      params.append('sort_order', sortOrder ? 'desc' : 'asc')

      if (sortType) params.append('sort_type', SortMap[sortType])

      selectedPDCOrganizations?.forEach((pdcOrganization) =>
        params.append('pdc_organization', pdcOrganization)
      )

      if (selectedStatus?.length)
        params.append('status', StatusFilterMap[selectedStatus])

      const [startDate, endDate] = selectedDateRange
      if (startDate) params.append('start_date', startDate)
      if (endDate) params.append('end_date', endDate)

      const currentParams = Object.fromEntries(
        new URLSearchParams(window.location.search).entries()
      )
      const newParams = Object.fromEntries(params.entries())

      if (!_.isEqual(currentParams, newParams)) {
        navigate(`${window.location.pathname}?${params.toString()}`, {
          replace: true
        })
      }
    }
  }, [
    init,
    search,
    sortOrder,
    sortType,
    selectedStatus,
    selectedPDCOrganizations,
    selectedDateRange,
    navigate
  ])

  useEffect(() => {
    if (urlParams) _fetchRequests()
  }, [_fetchRequests, urlParams, pageSize, currentPage])

  useEffect(() => {
    return () => {
      dispatch(resetVoucherSelection())
      dispatch(resetRequestsData())
    }
  }, [dispatch])

  return (
    <RequestsView
      items={requests}
      loading={loading}
      error={error}
      refetch={_fetchRequests}
      archiveRequest={_archiveRequest}
      archiveVoucher={_archiveVoucher}
      downloadVoucher={_downloadVoucher}
      selectVoucher={selectVoucher}
      deselectVoucher={deselectVoucher}
      selectMultipleVouchers={selectMultipleVouchers}
      deselectMultipleVouchers={deselectMultipleVouchers}
      pageSize={pageSize}
      pageSizeChanged={_pageSizeChanged}
      currentPage={currentPage}
      pageNumberChanged={_pageNumberChanged}
      hasNextPage={hasNextPage}
      nextPageRequested={_nextPageRequested}
      previousPageRequested={_previousPageRequested}
      selectedVouchers={selectedVouchers}
      init={init}
    />
  )
}

export default Requests
