import { PAGE_SIZES } from './constants'
import { Button, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

const PageSizeSelector = ({ pageSizeChanged, pageSize, disabled }) => {
  return (
    <div className="page-size-selector">
      <Popover2
        placement="right-start"
        disabled={disabled}
        content={
          <Menu>
            {PAGE_SIZES.map((size) => (
              <MenuItem
                key={`page-size-selector-${size}`}
                active={pageSize === size}
                text={size}
                onClick={() => pageSizeChanged(size)}
              />
            ))}
          </Menu>
        }
      >
        <Button icon="add-row-bottom" text={pageSize} disabled={disabled} />
      </Popover2>
    </div>
  )
}

export default PageSizeSelector
