import './styles.scss'

import { useState } from 'react'
import {
  Icon,
  Drawer,
  Button,
  MenuItem,
  Spinner,
  Position
} from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { Tooltip2 } from '@blueprintjs/popover2'

const OrgSwitcherView = ({
  loading,
  switchOrganization,
  organizations,
  currentOrganization,
  filterOrganizations
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(undefined)

  const renderItem = (item, { handleClick, modifiers }) => {
    return (
      <MenuItem
        key={item.org_id}
        text={item.org_name}
        active={modifiers.active}
        onClick={handleClick}
        label={item.company}
      />
    )
  }

  return (
    <div className="org-switcher-container">
      <div onClick={() => setIsOpen(!isOpen)} className="org-switcher-toggle">
        <Tooltip2
          className="menu-item"
          popoverClassName="menu-item-popover"
          rootBoundary="viewport"
          modifiers={{
            arrow: {
              enabled: false
            }
          }}
          content="Switch Organization"
        >
          <Icon icon="exchange" className="menu-item-link" />
        </Tooltip2>
      </div>
      <Drawer
        title="Switch Organization"
        isOpen={isOpen}
        size="450px"
        onClose={() => {
          setIsOpen(false)
          setSelectedOrganization(undefined)
        }}
        position={Position.BOTTOM}
        portalClassName="org-switcher-drawer"
        icon="exchange"
      >
        <div className="org-switcher-drawer-content">
          {loading && <Spinner className="org-switcher-spinner" />}
          {!loading && (
            <>
              <div className="org-switcher-current-org">
                You're currently operating as
                <div className="org-name">{currentOrganization?.org_name}</div>
              </div>
              <Select2
                items={organizations}
                itemRenderer={renderItem}
                onItemSelect={(item) => setSelectedOrganization(item)}
                itemPredicate={filterOrganizations}
                popoverProps={{
                  popoverClassName: 'org-switcher-popover',
                  placement: 'top',
                  usePortal: false,
                  minimal: true
                }}
              >
                <Button
                  className="org-switcher-button animate__animated animate__fadeInDown animate__fastest"
                  rightIcon="double-caret-vertical"
                  text={
                    selectedOrganization
                      ? selectedOrganization.org_name
                      : 'Choose a different organization'
                  }
                  large
                  minimal
                />
              </Select2>
              {selectedOrganization && (
                <>
                  <div className="org-switcher-callout animate__animated animate__flipInX animate__fast">
                    <div className="org-switcher-callout-header">
                      <Icon icon="warning-sign" />
                      You'll be logged out
                    </div>
                    When you log back in using SSO, you'll be switched to
                    the&nbsp;{selectedOrganization.org_name} organization.
                  </div>
                  {process.env.REACT_APP_ENABLE_TOKEN_LOGIN && (
                    <div className="org-switcher-callout-token-info animate__animated animate__fadeIn animate__faster">
                      <Icon icon="info-sign" />
                      Logging in using tokens will still take you to the
                      organization that the token was created in.
                    </div>
                  )}
                </>
              )}
              <Button
                className="org-switcher-confirm-button animate__animated animate__fadeInDown animate__fastest"
                icon="exchange"
                text={
                  selectedOrganization
                    ? `Switch to ${selectedOrganization.org_name}`
                    : `Switch`
                }
                disabled={!selectedOrganization}
                intent="success"
                onClick={() => switchOrganization(selectedOrganization)}
              />
              <Button
                className="org-switcher-cancel-button animate__animated animate__fadeInDown animate__fastest"
                text="Cancel"
                onClick={() => {
                  setIsOpen(false)
                  setSelectedOrganization(undefined)
                }}
              />
            </>
          )}
        </div>
      </Drawer>
    </div>
  )
}

export default OrgSwitcherView
