import {
  AVAILABLE_PERMISSIONS_REQUESTED,
  AVAILABLE_PERMISSIONS_REQUEST_SUCCESS,
  AVAILABLE_PERMISSIONS_REQUEST_FAILURE,
  ALERTS_REQUESTED,
  ALERTS_REQUEST_SUCCESS,
  ALERTS_REQUEST_FAILURE
} from './actions'
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from './components/Login/actions'
import { MY_ORGANIZATIONS_REQUEST_SUCCESS } from './components/OrgSwitcher/actions'

const defaultState = { meta: {} }

const app = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        consented: action.consented
      }
    case LOGOUT_SUCCESS:
      return { ...defaultState, alerts: state.alerts }
    case AVAILABLE_PERMISSIONS_REQUESTED:
      return {
        ...state,
        availablePermissionsLoading: true
      }
    case AVAILABLE_PERMISSIONS_REQUEST_FAILURE:
      return {
        ...state,
        availablePermissionsLoading: false
      }
    case AVAILABLE_PERMISSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        availablePermissionsLoading: false,
        permissions: action.permissions,
        permissionsList: action.permissionsList
      }
    case MY_ORGANIZATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        meta: action.meta
      }
    case ALERTS_REQUESTED:
      return {
        ...state,
        alertsLoading: true
      }
    case ALERTS_REQUEST_SUCCESS:
      return {
        ...state,
        alertsLoading: false,
        alert: action.alert
      }
    case ALERTS_REQUEST_FAILURE:
      return {
        ...state,
        alertsLoading: false
      }
    default:
      return state
  }
}
export default app
